import { Box, Button, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";

export default function AddContact({
  closeDrawer,
  someOneData,
  setSomeOneData,
}) {
  const { admin } = useSelector((state) => state);
  const [limit, setLimit] = useState({
    min: admin?.country === "UAE" || admin?.country === "KSA" ? 9 : 10,
    max: admin?.country === "UAE" || admin?.country === "KSA" ? 12 : 10,
  });

  const addContactSchema = Yup.object({
    number: Yup.string()
      .min(limit.min, `Minimum ${limit.min} numbers is required !`)
      .max(limit.max, `Maximum ${limit.max} numbers is allowed!`)
      .typeError("field must be a number")
      .required("requird"),
    name: Yup.string().required("Name is required"),
  });

  const initialValues = {
    number: someOneData?.number,
    name: someOneData?.name,
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: addContactSchema,
    onSubmit: async (values, action) => {
      setSomeOneData(values);
      closeDrawer();
    },
  });
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            height: "auto",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            margin: "0rem 10px 2rem 10px",
          }}
        >
          <Typography sx={{ fontSize: "2.4rem", fontWeight: "400" }}>
            ADD CONTACT
          </Typography>
          <Typography
            sx={{ fontSize: "1.3rem", fontWeight: "400", color: "#909090" }}
          >
            Enter details of person you want to gift
          </Typography>
          <Typography
            sx={{
              fontSize: "1.5rem",
              fontWeight: "400",
            }}
          >
            Phone Number
          </Typography>
          <TextField
            variant="standard"
            type="number"
            placeholder="Enter Your Number"
            inputProps={{ style: { fontSize: "1.5rem" } }} // font size of input text
            FormHelperTextProps={{ style: { fontSize: "1.5rem" } }}
            name="number"
            value={formik.values.number}
            onChange={formik.handleChange}
            error={!!formik.touched.number && !!formik.errors.number}
            helperText={formik.touched.number && formik.errors.number}
          />

          <Typography
            sx={{
              fontSize: "1.5rem",
              fontWeight: "400",
            }}
          >
            Full Name
          </Typography>
          <TextField
            variant="standard"
            placeholder="Enter Your Name"
            FormHelperTextProps={{ style: { fontSize: "1.5rem" } }}
            inputProps={{ style: { fontSize: "1.5rem" } }} // font size of input text
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={!!formik.touched.name && !!formik.errors.name}
            helperText={formik.touched.name && formik.errors.name}
          />
          <Button
            sx={{
              backgroundColor: "#FF2E2E",
              color: "#fff",
              height: "45px",
              borderRadius: "10px",
              fontSize: "1.3rem",
              fontWeight: "400",
              mt: 2,
              "&:hover": {
                backgroundColor: "#FF2E2E",
              },
            }}
            type="submit"
          >
            Continue
          </Button>
        </Box>
      </form>
    </>
  );
}
