import { useCallback, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import Drawer from "react-bottom-drawer";
import LoginDrawer from "./Login";

export default function LoginPrompt({ returnSataus, closeprevious }) {
  const [isVisible, setIsVisible] = useState(false);
  const openDrawer = useCallback(() => setIsVisible(true), []);
  const closeDrawer = useCallback(() => {
    setIsVisible(false);
    closeprevious();
  }, []);

  return (
    <Box
      sx={{
        height: "auto",
        margin: "0rem 10px 2rem 10px",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <Typography sx={{ fontSize: "2.4rem", fontWeight: "400" }}>
        Login
      </Typography>
      <Typography
        sx={{
          color: "rgba(144, 144, 144, 1)",
          fontSize: "1.5rem",
          fontWeight: "400",
        }}
      >
        Login/ Create Account to place order
      </Typography>
      <Button
        sx={{
          backgroundColor: "#FF2E2E",
          color: "#fff",
          height: "40px",
          borderRadius: "10px",
          fontSize: "1.3rem",
          fontWeight: "400",
          "&:hover": {
            backgroundColor: "#FF2E2E",
          },
        }}
        className="open-btn"
        onClick={() => {
          openDrawer();
        }}
      >
        Proceed with Phone Number
      </Button>
      <Drawer
        duration={250}
        hideScrollbars={true}
        onClose={closeDrawer}
        isVisible={isVisible}
      >
        <LoginDrawer returnSataus={returnSataus} closeprevious={closeDrawer} />
      </Drawer>
    </Box>
  );
}
