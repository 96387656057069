import { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import TopBar from "../components/common/TopBar";
import StarIcon from "@mui/icons-material/Star";
import Drawer from "react-bottom-drawer";
import DrawerContent from "../components/common/AddItem";
import { useDispatch, useSelector } from "react-redux";
import Lottie from "lottie-react";
import { useNavigate } from "react-router-dom";
import LoginPromptDrawer from "../components/login/LoginPrompt";
import deliveryBoy from "../lottie/delivery-boy.json";
import { useCallback } from "react";
import RemoveItem from "../components/common/RemoveItem";
import { removeItemFromFavourites } from "../store/favouriteslice";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100dvh",
    width: "100vw",
    boxSizing: "border-box",
    padding: "2.5rem 1.5rem 2.5rem 1.5rem",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "rgba(248, 248, 248, 1)",
  },
}));

export default function Favourite() {
  const navigate = useNavigate();
  const { user, ourfavourites, ourproductsdata, admin } = useSelector(
    (state) => state
  );
  const [loading, setLoading] = useState("spinner");
  const [favProducts, setFavProducts] = useState([]);
  const [deleteFavProductId, setDeleteFavProductId] = useState(null);
  const dispatch = useDispatch();

  const [selectedItem, setSelectedItem] = useState(null);
  const [isVisibleProduct, setIsVisibleProduct] = useState(false);

  const classes = useStyles();

  const [isVisible, setIsVisible] = useState(false);
  const openDrawer = useCallback(() => setIsVisible(true), []);
  const closeDrawer = useCallback(() => setIsVisible(false), []);

  const openProductDrawer = useCallback((item) => {
    setSelectedItem(item);
    setIsVisibleProduct(true);
  }, []);
  const closeProductDrawer = useCallback(() => setIsVisibleProduct(false), []);

  const openProductDetails = (item) => {
    if (!user) {
      openDrawer();
    } else {
      openProductDrawer(item);
    }
  };

  const handleDeleteFavProduct = async () => {
    dispatch(removeItemFromFavourites(deleteFavProductId));
  };

  const [removeItemModalOpen, setRemoveItemModalOpen] = useState(false);
  const removeItemModalhandleOpen = () => setRemoveItemModalOpen(true);
  const removeItemModalhandleClose = () => setRemoveItemModalOpen(false);
  console.log(ourfavourites);

  const fetchItems = async () => {
    const favitem = ourfavourites
      ?.map((item) => ourproductsdata.find((pro) => pro._id === item))
      .filter(Boolean);
    if (!favitem.length) {
      setLoading("lottie");
    } else {
      setLoading("item");
    }
    setFavProducts(favitem);
  };
  useEffect(() => {
    fetchItems();
  }, [ourfavourites]);
  return (
    <Box>
      {loading === "spinner" ? (
        <Box
          sx={{
            width: "100%",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      ) : loading === "lottie" ? (
        <Box className={classes.container}>
          <TopBar backTo={"/profile"} />
          <Box
            sx={{ width: "277px", height: "277px", alignSelf: "center", mt: 2 }}
          >
            <Lottie animationData={deliveryBoy} />
          </Box>
          <Typography
            sx={{
              fontSize: "2rem",
              fontWeight: "400",
              alignSelf: "center",
              mt: 2,
            }}
          >
            No Favourites
          </Typography>
          <Box sx={{ alignSelf: "center" }}>
            <Button
              sx={{
                backgroundColor: "#FF2E2E",
                color: "#fff",
                width: "250px",
                height: "58px",
                fontSize: "1.7rem",
                fontWeight: "400",
                borderRadius: "20px",
                alignSelf: "center",
                mt: 2,
                "&:hover": {
                  backgroundColor: "#FF2E2E",
                },
              }}
              onClick={() => navigate("/")}
            >
              Continue Shopping
            </Button>
          </Box>
        </Box>
      ) : (
        <Box className={classes.container}>
          <TopBar backTo={"/profile"} />
          <Typography sx={{ fontSize: "1.5rem", fontWeight: "500", mt: 2 }}>
            Favourite
          </Typography>
          <Box sx={{ height: "auto", overflowY: "scroll" }}>
            {console.log(favProducts)}
            {favProducts?.map((item) => {
              return (
                <Box
                  sx={{
                    height: "auto",
                    display: "flex",
                    justifyContent: "space-between",
                    bgcolor: "#fff",
                    my: 2,
                    px: 3,
                    pt: 2,
                  }}
                  key={item._id}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                    }}
                  >
                    <Typography sx={{ fontSize: "2rem" }}>
                      {item.title
                        .toLowerCase()
                        .replace(/\b\w/g, (c) => c.toUpperCase())}
                    </Typography>
                    <Typography sx={{ fontSize: "2rem" }}>
                      <span style={{ fontSize: "1.6rem" }}>
                        {admin?.currencySymbol}&nbsp;
                      </span>
                      {(Math.max.apply(
                        null,
                        item?.varients?.map(
                          (item) => item?.varientId?.salePrice
                        )
                      )).toFixed(2)}
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <StarIcon sx={{ color: "#FE9E00", fontSize: "2rem" }} />{" "}
                      <StarIcon sx={{ color: "#FE9E00", fontSize: "2rem" }} />{" "}
                      <StarIcon sx={{ color: "#FE9E00", fontSize: "2rem" }} />{" "}
                      <StarIcon sx={{ color: "#D1D1D1", fontSize: "2rem" }} />{" "}
                      <StarIcon sx={{ color: "#D1D1D1", fontSize: "2rem" }} />{" "}
                      <Typography
                        sx={{ color: "#FE9E00", fontSize: "1.5rem", px: 1 }}
                      >
                        4.0
                      </Typography>
                    </Box>
                    <Typography
                      sx={{ color: "#9D9D9D", fontSize: "1.6rem" }}
                      onClick={() => {
                        setDeleteFavProductId(item._id);
                        removeItemModalhandleOpen();
                      }}
                    >
                      Remove
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "125px",
                      height: "160px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      position: "relative",
                    }}
                    onClick={() => openProductDetails(item)}
                  >
                    <img
                      src={item?.varients[0]?.varientId?.image}
                      alt="biriyani"
                      style={{
                        objectFit: "cover",
                        width: "125px",
                        height: "125px",
                        borderRadius: "10px",
                      }}
                    />
                    <Button
                      sx={{
                        backgroundColor: "#FF2E2E",
                        color: "#fff",
                        width: "90px",
                        fontSize: "1.1rem",
                        borderRadius: "10px",
                        position: "absolute",
                        bottom: 20,
                        "&:hover": {
                          backgroundColor: "#FF2E2E",
                        },
                      }}
                      className="open-btn"
                    >
                      ADD
                    </Button>
                  </Box>
                </Box>
              );
            })}
          </Box>
          <Drawer
            duration={250}
            hideScrollbars={true}
            onClose={closeProductDrawer}
            isVisible={isVisibleProduct}
          >
            <DrawerContent
              closeDrawer={closeProductDrawer}
              selectedItem={selectedItem}
            />
          </Drawer>
          <Drawer
            duration={250}
            hideScrollbars={true}
            onClose={closeDrawer}
            isVisible={isVisible}
          >
            <LoginPromptDrawer closeprevious={closeDrawer} />
          </Drawer>
          {/*  */}
          <Box>
            <Modal
              open={removeItemModalOpen}
              onClose={removeItemModalhandleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <RemoveItem
                removeItemModalhandleClose={removeItemModalhandleClose}
                handleDeleteFavProduct={handleDeleteFavProduct}
                head1={"product"}
              />
            </Modal>
          </Box>
          {/*  */}
        </Box>
      )}
    </Box>
  );
}
