import { Link } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import { useSelector } from "react-redux";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  height: "45vh",
  bgcolor: "#000",
  borderRadius: "20px",
  boxShadow: 24,
  p: "4rem 1rem",
  margin: "auto",
  display: "flex",
  flexDirection: "column",
};
export default function MenuModal({ handleClose }) {
  const { ourcategories } = useSelector((state) => state);
  return (
    <Box sx={style}>
      <Box
        sx={{
          bgcolor: "#000",
          position: "fixed",
          p: 2,
          borderRadius: "50%",
          top: "-35px",
          right: "35vw",
          zIndex: "1",
        }}
      >
        <AutoStoriesIcon sx={{ color: "#fff", fontSize: "3rem" }} />
      </Box>
      <Typography
        sx={{
          fontSize: "2rem",
          fontWeight: "400",
          color: "#fff",
          alignSelf: "center",
        }}
      >
        Menu
      </Typography>
      <Box sx={{ borderTop: "1px solid #fff", mt: 1 }}></Box>
      <Box
        sx={{
          mt: 2,
          display: "flex",
          flexWrap: "wrap",
          gap: "0px",
          height: "auto",
          overflowY: "scroll",
          "&::-webkit-scrollbar": {
            display: "none", // Hide scrollbar
          },
        }}
      >
        {ourcategories.map((item, i) => (
          <Link
            to={`/menu/${item.id}`}
            key={item.id}
            style={{
              textDecoration: "none",
              color: "#000",
              width: "100%",
            }}
            onClick={handleClose}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  boxSizing: "border-box",
                  mt: 2,
                  px: 2,
                }}
              >
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: "1.6rem",
                    fontWeight: "400",
                  }}
                >
                  {/* {item.groupName} */}
                  {item.groupName
                    .toLowerCase()
                    .replace(/\b\w/g, (c) => c.toUpperCase())}
                </Typography>
              </Box>
            </Box>
          </Link>
        ))}
      </Box>
    </Box>
  );
}
