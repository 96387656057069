import Cookies from "js-cookie";

export function AddressReducer(
  state = Cookies.get("address") ? JSON.parse(Cookies.get("address")) : null,
  action
) {
  switch (action.type) {
    case "ADDRESS":
      return action.payload;
    default:
      return state;
  }
}
