import { Box, Typography } from "@mui/material";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import Moment from "react-moment";
import { Link, useLocation } from "react-router-dom";

export default function OrdersCard({ item, admin }) {
  const location = useLocation();
  return (
    <Box
      sx={{
        width: "100%",
        height: "140px",
        borderRadius: "2rem",
        bgcolor: "#fff",
        boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
        backdropFilter: "blur(2px)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Link
        to="/orders"
        state={location.pathname}
        style={{ textDecoration: "none", color: "black" }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            p: "1rem 2rem",
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: "400",
                color: "#999999",
              }}
            >
              {
                <Moment format="D MMM YY - h:mm a" withTitle>
                  {item[0]?.createdAt}
                </Moment>
              }
            </Typography>
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: "400",
                color: "#999999",
              }}
            >
              Order # {item[0].invoiceNo}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>
            <RadioButtonCheckedIcon sx={{ color: "#2D8CE3" }} />
            <Box
              sx={{
                px: "10px",
                py: "3px",
                border: "1px solid #2D8CE3",
                borderRadius: "5px",
                color: "#2D8CE3",
                background: "#2D8CE333",
              }}
            >
              <Typography sx={{ fontSize: "1.1rem" }}>
                {item[0].orderStatus}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{ borderTop: "1px dotted gray", width: "100%" }}></Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            p: "1rem 2rem",
          }}
        >
          <Box sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>
            <Box sx={{ height: "53px", width: "53px" }}>
              <img
                src={admin?.logo}
                alt="logo"
                style={{ width: "100%", height: "100%" }}
              />
            </Box>
            <Box>
              <Typography sx={{ fontSize: "1.7rem", fontWeight: "400" }}>
                {admin?.city}
              </Typography>
              <Typography sx={{ fontSize: "1.1rem", fontWeight: "400" }}>
                {admin?.businessName}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Typography sx={{ fontSize: "1.1rem", fontWeight: "400" }}>
              {item[0]?.products?.length} items
            </Typography>
            <Typography sx={{ fontSize: "1.5rem", fontWeight: "400" }}>
              <span style={{ fontSize: "1.2rem" }}>
                {admin?.currencySymbol}&nbsp;
              </span>
              {item[0]?.grandTotal}
            </Typography>
          </Box>
        </Box>
      </Link>
    </Box>
  );
}
