import { Box, Divider, Modal, Typography } from "@mui/material";
import Cookies from "js-cookie";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70vw",
  height: "auto",
  bgcolor: "#fff",
  borderRadius: "20px",
  boxShadow: 24,
  p: "2rem 2rem",
  margin: "auto",
  display: "flex",
  flexDirection: "column",
};

export default function Logout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logout = () => {
    Cookies.set("user", "");
    dispatch({
      type: "LOGOUT",
    });
    localStorage.removeItem("ourproductsdata");
    navigate("/");
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          backgroundColor: "#FF2E2E",
          borderRadius: "5px",
          color: "#FFFFFF",
          position: "fixed",
          m: "10px",
          bottom: "0",
          left: "0",
          right: "0",
          height: "60px",
        }}
        onClick={handleOpen}
      >
        <Typography sx={{ fontSize: "1.6rem" }}>Logout</Typography>
      </Box>
      <Box>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              sx={{
                fontSize: "1.7rem",
                fontWeight: "500",
                marginInline: "auto",
                textAlign: "center",
                my: 2,
                color: "#878787",
              }}
            >
              Are your sure?
            </Typography>
            <Typography
              sx={{
                fontSize: "1.3rem",
                fontWeight: "500",
                color: "#C5C5C5",
                marginInline: "auto",
                textAlign: "center",
              }}
            >
              Are you sure you want to logout from your account?
            </Typography>
            <Divider sx={{ mt: 2 }} />
            <Box sx={{ display: "flex" }}>
              <Typography
                onClick={handleClose}
                sx={{
                  color: "#F04141",
                  marginInline: "auto",
                  mt: 2,
                  px: 2,
                  fontSize: "1.5rem",
                  fontWeight: "400",
                }}
              >
                Cancel
              </Typography>
              <Typography
                onClick={logout}
                sx={{
                  color: "#C5C5C5",
                  marginInline: "auto",
                  mt: 2,
                  fontSize: "1.5rem",
                  px: 2,
                  fontWeight: "400",
                }}
              >
                Logout
              </Typography>
            </Box>
          </Box>
        </Modal>
      </Box>
    </>
  );
}
