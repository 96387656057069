import axios from "axios";
const host = process.env.REACT_APP_BACKEND_URL;
const adminId = process.env.REACT_APP_ADMIN_ID;

export const fetchCategories = async () => {
  try {
    const { data } = await axios.get(`${host}/getallgroup/${adminId}`);
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const fetchMainBanner = async () => {
  try {
    const { data } = await axios.get(`${host}/getMainBanner/${adminId}`);
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const fetchSubBanner = async () => {
  try {
    const { data } = await axios.get(`${host}/getSubBanner/${adminId}`);
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const fetchBestSellers = async () => {
  try {
    const { data } = await axios.get(`${host}/bestSellerProducts/${adminId}`);
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
