import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ProfileDetails from "../components/profile/ProfileDetails";
import OrderFavouriteAndAddressTabs from "../components/profile/OrderFavouriteAndAddressTabs";
import Logout from "../components/profile/Logout";
import TopBar from "../components/common/TopBar";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100dvh",
    width: "100vw",
    boxSizing: "border-box",
    padding: "2.5rem 1.5rem 2.5rem 1.5rem",
    backgroundColor: "#FAFAFA",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
}));

export default function Profile() {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <TopBar backTo={"/"} />
      <ProfileDetails />
      <OrderFavouriteAndAddressTabs />
      <Logout />
    </Box>
  );
}
