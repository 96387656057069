import Cookies from "js-cookie";

export function OurMainBannersReducer(
  state = Cookies.get("ourmainbanners")
    ? JSON.parse(Cookies.get("ourmainbanners"))
    : [],
  action
) {
  switch (action.type) {
    case "OURMAINBANNERS":
      return action.payload;
    default:
      return state;
  }
}
