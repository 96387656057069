import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";

export default function BestsellerCard({ item, openProductDetails }) {
  const { admin } = useSelector((state) => state);
  return (
    <Box
      key={item._id}
      sx={{
        display: "flex",
        flexDirection: "column",
        // height: "190px",
        width: "170px",
        borderRadius: "5px",
        boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.15)",
        position: "relative",
      }}
      onClick={() => openProductDetails(item)}
    >
      <Box
        sx={{
          position: "absolute",
          top: 10,
          left: 10,
          zIndex: 1,
          bgcolor: "orange",
          color: "white",
          px: 1,
          borderRadius: "3px",
        }}
      >
        <Typography>Bestseller</Typography>
      </Box>
      <img
        src={item.varients[0].varientId.image}
        style={{
          objectFit: "cover",
          width: "170px",
          height: "130px",
          borderRadius: "5px",
        }}
        alt="best seller"
      />
      <Typography sx={{ fontSize: "1.6rem", fontWeight: "500", fontFamily: "Inter", px: "0.7rem", pt: "0.7rem" }}>
        {/* {item.varients[0].varientId.productName} */}
        {item.title.toLowerCase().replace(/\b\w/g, (c) => c.toUpperCase())}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          px: "0.7rem",
          gap: "5px",
        }}
      >
        <Typography
          sx={{
            fontSize: "1.4rem",
            fontWeight: "500",
            fontFamily: "Inter",
          }}
        >
          {admin?.currencySymbol}&nbsp;
          {Math.max.apply(
            null,
            item?.varients?.map((item) => item?.varientId?.salePrice)
          ).toFixed(2)}
        </Typography>
      </Box>
    </Box>
  );
}
