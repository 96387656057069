import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import TopBar from "../components/common/TopBar";
import AddressCard from "../components/address/AddressCard";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100dvh",
    width: "100vw",
    boxSizing: "border-box",
    padding: "2.5rem 0 2.5rem 0",
    backgroundColor: "#FAFAFA",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
}));

export default function Address() {
  const navigate = useNavigate();
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <TopBar />
      <AddressCard />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          backgroundColor: "#FF2E2E",
          borderRadius: "5px",
          color: "#FFFFFF",
          position: "fixed",
          m: "10px",
          bottom: "0",
          left: "0",
          right: "0",
          height: "60px",
        }}
        onClick={() => navigate("/map")}
      >
        <Typography sx={{ fontSize: "1.6rem" }}>Add Address</Typography>
      </Box>
    </Box>
  );
}
