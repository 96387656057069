import { Box, Typography, Button } from "@mui/material";
import AdjustOutlinedIcon from "@mui/icons-material/AdjustOutlined";
import { useSelector } from "react-redux";
import Moment from "react-moment";

export default function OrderCard({ item, cancelOrderFunc, setCancelItemId }) {
  const { admin } = useSelector((state) => state);

  return (
    <Box
      sx={{
        background: "#FFFF",
        p: "10px",
        display: "flex",
        flexDirection: "column",
        gap: "5px",
        borderRadius: "5px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          py: "10px",
          alignItems: "center",
          borderBottom: "1px dotted #C0C0C0	",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <Box sx={{ height: "77px", width: "77px" }}>
            <img
              src={admin.logo}
              alt="logo"
              style={{ width: "100%", height: "100%" }}
            />
          </Box>
          <Box>
            <Typography sx={{ fontSize: "1.5rem", fontWeight: "500" }}>
              {admin.businessName}
            </Typography>
            <Typography sx={{ fontSize: "1.1rem", fontWeight: "400" }}>
              {admin.city}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <AdjustOutlinedIcon
            sx={{ color: "#2D8CE3", height: "20px", width: "20px" }}
          />
          <Box
            sx={{
              px: "10px",
              py: "5px",
              border: "1px solid #2D8CE3",
              borderRadius: "5px",
              color: "#2D8CE3",
              background: "#2D8CE333",
            }}
          >
            <Typography sx={{ fontSize: "1.1rem" }}>
              {item.orderStatus}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          p: "10px",
          borderBottom: "1px dotted #C0C0C0	",
        }}
      >
        {item.products.map((row, index) => (
          <Box key={row._id}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ display: "flex", gap: "5px" }}>
                <Typography sx={{ fontSize: "1.4rem" }}>
                  {index + 1}.
                </Typography>
                <Typography sx={{ fontSize: "1.4rem" }}>
                  {row.varientId.productName
                    .toLowerCase()
                    .replace(/\b\w/g, (c) => c.toUpperCase())}
                  {/* {row.productId.title} */} x {row.quantity}
                </Typography>
              </Box>
              <Typography sx={{ fontSize: "1.4rem" }}>
                <span style={{ fontSize: "1.2rem" }}>
                  {admin?.currencySymbol}&nbsp;
                </span>
                {(row.salePrice * row.quantity).toFixed(2)}
              </Typography>
            </Box>
            {row?.addOns?.map((addon) => (
              <Box
                key={addon?._id}
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Box sx={{ display: "flex", gap: "5px" }}>
                  <Typography sx={{ fontSize: "1.4rem" }}>
                    &nbsp;&nbsp;&nbsp;
                  </Typography>
                  <Typography sx={{ fontSize: "1.4rem" }}>
                    {addon.name} x {addon.quantity}
                  </Typography>
                </Box>
                <Typography sx={{ fontSize: "1.4rem" }}>
                  <span style={{ fontSize: "1.2rem" }}>
                    {admin?.currencySymbol}&nbsp;
                  </span>
                  {addon.price}
                </Typography>
              </Box>
            ))}
          </Box>
        ))}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          px: "10px",
        }}
      >
        <Typography sx={{ fontSize: "1.4rem", color: "#999999" }}>
          Delivery Charge
        </Typography>
        <Typography sx={{ fontSize: "1.6rem", fontWeight: "bold" }}>
          <span style={{ fontSize: "1.4rem" }}>
            {admin?.currencySymbol}&nbsp;
          </span>
          {item.deliveryCharge.toFixed(2)}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          px: "10px",
          borderBottom: "1px dotted #C0C0C0	",
        }}
      >
        <Typography sx={{ fontSize: "1.4rem", color: "#999999" }}>
          {admin?.gstOrVat}
        </Typography>
        <Typography sx={{ fontSize: "1.6rem", fontWeight: "bold" }}>
          <span style={{ fontSize: "1.4rem" }}>
            {admin?.currencySymbol}&nbsp;
          </span>
          {item.gstAmount.toFixed(2)}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          px: "10px",
        }}
      >
        <Typography sx={{ fontSize: "1.4rem", color: "#999999" }}>
          <Moment format="D MMM YY - h:mm a" withTitle>
            {item?.createdAt}
          </Moment>
        </Typography>
        <Typography sx={{ fontSize: "1.6rem", fontWeight: "bold" }}>
          <span style={{ fontSize: "1.4rem" }}>
            {admin?.currencySymbol}&nbsp;
          </span>
          {item.grandTotal.toFixed(2)}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          px: "10px",
          my: "10px",
          alignItems: "center",
        }}
      >
        <Typography sx={{ fontSize: "1.4rem", color: "#53CA6C" }}>
          Payment mode {item?.paymentMethod?.toLowerCase()}
        </Typography>
        {item.orderStatus === "Pending" && (
          <Button
            variant="text"
            sx={{
              textTransform: "none",
              fontSize: "1.2rem",
            }}
            onClick={() => {
              setCancelItemId(item._id);
              cancelOrderFunc();
            }}
          >
            Cancel Order
          </Button>
        )}
      </Box>
    </Box>
  );
}
