import { Box, Skeleton, Typography } from "@mui/material";
import { useSelector } from "react-redux";

export default function ExclusiveDishes({
  randomlyPickedItem,
  openProductDetails,
}) {
  const { admin } = useSelector((state) => state);
  return (
    <Box>
      <Typography
        sx={{
          fontSize: "2rem",
          fontWeight: "500",
          margin: "2rem 0 0rem 0",
          fontFamily: 'Inter',
        }}
      >
        Exclusive Dishes
      </Typography>
      {randomlyPickedItem ? (
        <Box
          key={randomlyPickedItem?._id}
          sx={{
            display: "flex",
            flexDirection: "column",
            // height: "305px",
            borderRadius: "5px",
            boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.15)",
            position: "relative",
            margin: "2rem 0 2rem 0",
          }}
          onClick={() => openProductDetails(randomlyPickedItem)}
        >
          <img
            src={randomlyPickedItem?.varients[0]?.varientId?.image}
            style={{
              objectFit: "cover",
              width: "100%",
              height: "20vh",
              borderRadius: "5px",
            }}
            alt="best seller"
          />
          <Typography
            sx={{ fontSize: "1.8rem", fontWeight: "500", p: "0.7rem", fontFamily: 'Inter', }}
          >
            {/* {randomlyPickedItem?.varients[0]?.varientId?.productName} */}
            {randomlyPickedItem.title
              .toLowerCase()
              .replace(/\b\w/g, (c) => c.toUpperCase())}
          </Typography>
          <Typography
            sx={{ color: "#9D9D9D", fontFamily: 'Inter', fontSize: "1.5rem", p: "0.7rem" }}
          >
            Most picked trending dishes for you
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: "0.7rem",
            }}
          >
            <Typography
              sx={{
                fontSize: "1.8rem",
                fontWeight: "500",
                fontFamily: 'Inter',
              }}
            >
              <span style={{ fontSize: "1.8rem" }}>
                {admin?.currencySymbol}&nbsp;
              </span>
              {Math.max.apply(
                null,
                randomlyPickedItem?.varients?.map(
                  (item) => item?.varientId?.salePrice
                )
              ).toFixed(2)}
            </Typography>
            <Box
              sx={{
                color: "#CB2647",
                border: "0.5px solid #CB2647",
                p: "0rem 1rem",
                fontSize: "1.5rem",
                borderRadius: "5px",
              }}
            >
              <Typography sx={{ fontSize: "1.7rem", fontFamily: 'Inter', fontWeight: "400" }}>
                View
              </Typography>
            </Box>
          </Box>
        </Box>
      ) : (
        <Skeleton
          variant="rectangular"
          width={"100%"}
          height={"20vh"}
          sx={{ borderRadius: "5px", margin: "2rem 0 2rem 0" }}
        />
      )}
    </Box>
  );
}
