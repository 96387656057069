import axios from "axios";
import { placeOrder } from "./order";
import { clearCart } from "../store/cartslice";
import rootReducer from "../store/rootReducer";
import { createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
const host = process.env.REACT_APP_BACKEND_URL;

const store = createStore(rootReducer, composeWithDevTools());

export const handleOpenRazorpay = (
  data,
  orderData,
  orderModalHandleOpen,
  setisLoading
) => {
  console.log(data);
  const options = {
    key: process.env.RAZORPAY_KEY_ID,
    amount: Number(data.amount),
    curency: data.currency,
    name: "Our Onlin Service",
    description: "Our Onlin Serviceeeeeeeee",
    order_id: data.id,
    handler: function (response) {
      console.log(response);
      axios
        .post(`${host}/razorpayVerify`, { response: response })
        .then(async (res) => {
          console.log(res);
          setisLoading(true);
          const OrderRes = await placeOrder({
            ...orderData,
            paymentGateway: {
              isPaid: true,
              orderId: response.razorpay_order_id,
              paymentId: response.razorpay_payment_id,
              signature: response.razorpay_signature,
              amount_paid: data.amount / 100,
            },
          });
          console.log(OrderRes);
          if (OrderRes?.status === 200) {
            orderModalHandleOpen();
            setTimeout(() => {
              store.dispatch(clearCart());
              window.location.href = "/";
            }, 2000);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  };
  const rzp = new window.Razorpay(options);
  rzp.open();
};
export const handlePayment = (
  orderData,
  orderModalHandleOpen,
  setisLoading
) => {
  // <Navigate to="/" />;

  const _data = { amount: orderData.grandTotal };
  console.log(_data, "sadasda   57");
  axios
    .post(`${host}/razorpayOrders`, _data)
    .then(async (res) => {
      console.log(res.data);
      handleOpenRazorpay(
        res?.data,
        orderData,
        orderModalHandleOpen,
        setisLoading
      ).then((responce) => {
        return responce;
        // console.log(responce);
      });
      // console.log(data);
    })
    .catch((err) => {
      console.log(err);
    });
};
