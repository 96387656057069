import { Link } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import YouTubeIcon from "@mui/icons-material/YouTube";
import igLogo from "../../assets/igLogo.png";

export default function Footer({ admin }) {
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
          height: "200px",
          borderRadius: "5px",
          boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.15)",
          margin: "2rem 0 2rem 0",
        }}
      >
        <Typography sx={{ fontSize: "2rem", fontWeight: "200" }}>
          Your opinion matters to us!
        </Typography>
        <Typography sx={{ color: "#9D9D9D", fontSize: "1.1rem", p: "0.7rem" }}>
          Give us a quick review and help us improve
        </Typography>
        <Box sx={{ display: "flex" }}>
          <StarIcon sx={{ color: "#FE9E00", fontSize: "2.3rem" }} />{" "}
          &nbsp;&nbsp;&nbsp;
          <StarIcon sx={{ color: "#FE9E00", fontSize: "2.3rem" }} />{" "}
          &nbsp;&nbsp;&nbsp;
          <StarIcon sx={{ color: "#FE9E00", fontSize: "2.3rem" }} />{" "}
          &nbsp;&nbsp;&nbsp;
          <StarIcon sx={{ color: "#D1D1D1", fontSize: "2.3rem" }} />{" "}
          &nbsp;&nbsp;&nbsp;
          <StarIcon sx={{ color: "#D1D1D1", fontSize: "2.3rem" }} />{" "}
          &nbsp;&nbsp;&nbsp;
        </Box>
        <Link to={admin?.googleAccount} style={{ textDecoration: "none" }}>
          <Button
            sx={{
              backgroundColor: "#FF2E2E",
              color: "#fff",
              width: "150px",
              fontSize: "1.1rem",
              borderRadius: "10px",
              "&:hover": {
                backgroundColor: "#FF2E2E",
              },
            }}
          >
            Submit
          </Button>
        </Link>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "3rem",
        }}
      >
        <Link to={admin?.instagram} style={{ textDecoration: "none" }}>
          <img
            src={igLogo}
            alt="ig logo"
            style={{ width: "3.3rem", height: "3.3rem" }}
          />
        </Link>
        <Link to={admin?.youTube} style={{ textDecoration: "none" }}>
          <YouTubeIcon sx={{ color: "#ED1D24", fontSize: "4.7rem" }} />
        </Link>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          color: "#C8C8C8",
          width: "100%",
          // py: "2rem",
          zIndex: 1000,
        }}
      >
        <Link
          to="https://artifitia.com"
          style={{ textDecoration: "none", width: "100%" }}
        >
          <Typography
            sx={{ fontSize: "1.3rem", color: "#C8C8C8", textAlign: "center" }}
          >
            Powered by
          </Typography>
          <Typography
            sx={{ fontSize: "1.8rem", color: "#C8C8C8", textAlign: "center" }}
          >
            www.artifitia.com
          </Typography>
        </Link>
      </Box>
    </Box>
  );
}
