import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import { Box, Typography, useMediaQuery } from "@mui/material";
import monitorError from "../src/assets/monitorError.png";
import "./App.css";
import Profile from "./pages/Profile";
import Menu from "./pages/Menu";
import Address from "./pages/Address";
import Orders from "./pages/Orders";
import Cart from "./pages/Cart";
import GiftInformation from "./pages/GiftingInformation";
import Map from "./pages/Map";
import Favourite from "./pages/Favourite";
import { useEffect } from "react";
import { getAdminDetails } from "./sevice/login";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import Payment from "./pages/Payment";
import Search from "./pages/Search";
import { Offline, Online } from "react-detect-offline";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import LoggedInRoutes from "././routes/LoggedInRoutes";
import NetworkErrorPage from "./pages/NetworkErrorPage";

function App() {
  const dispatch = useDispatch();
  const isTvScreen = useMediaQuery("(max-width: 550px)");
  useEffect(() => {
    findAdminAddressData();
  }, []);
  const findAdminAddressData = async () => {
    const res = await getAdminDetails();
    if (res.data) {
      dispatch({ type: "ADMINDATA", payload: res.data });
      Cookies.set("admin", JSON.stringify(res.data));
    }
  };

  return (
    <>
      <Online>
        <ToastContainer />
        {isTvScreen ? (
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/menu/:categoryId" element={<Menu />} />
            <Route path="/search" element={<Search />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/giftinginfo" element={<GiftInformation />} />
            <Route path="/map" element={<Map />} />
            <Route element={<LoggedInRoutes />}>
              <Route path="/profile" element={<Profile />} />
              <Route path="/address" element={<Address />} />
              <Route path="/orders" element={<Orders />} />
              <Route path="/favourite" element={<Favourite />} />
              {/* <Route path="/payment" element={<Payment />} /> */}
              {/* <Route path="/checkout-success" element={<CheckoutSuccess />} /> */}
            </Route>
          </Routes>
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
              flexDirection: "column",
              background: "#351311",
            }}
          >
            <img
              src={monitorError}
              alt="mobile error"
              style={{ width: "50vw" }}
            />
            <Typography
              sx={{
                color: "white",
                fontSize: "2rem",
                marginTop: 2,
                marginRight: 3,
                marginLeft: 3,
                textAlign: "center",
                fontFamily: "poppins",
              }}
            >
              Desktop Preview is not available; please open in a mobile browser.
            </Typography>
          </Box>
        )}
      </Online>
      <Offline>
        <NetworkErrorPage />
      </Offline>
    </>
  );
}

export default App;
