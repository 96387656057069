import Cookies from "js-cookie";

export function AdminReducer(
  state = Cookies.get("admin") ? JSON.parse(Cookies.get("admin")) : null,
  action
) {
  switch (action.type) {
    case "ADMINDATA":
      return action.payload;
    default:
      return state;
  }
}
