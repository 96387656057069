export function ProductsReducer(
  state = localStorage.getItem("ourproductsdata")
    ? JSON.parse(localStorage.getItem("ourproductsdata"))
    : [],
  action
) {
  switch (action.type) {
    case "OURPRODUCTSDATA":
      return action.payload;
    default:
      return state;
  }
}
