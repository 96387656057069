import { Box, Button, Checkbox, TextField, Typography } from "@mui/material";
import Drawer from "react-bottom-drawer";
import { useFormik } from "formik";
import VerifyDrawer from "./Verify";
import { loginWithOutOtp, loginWithPhone, sendOtp } from "../../sevice/login";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const initialValues = {
  number: "",
  name: "",
  termsAndCondition: false,
};
export default function Login({ returnSataus, closeprevious }) {
  const { admin } = useSelector((state) => state);
  const [limit, setLimit] = useState({
    min: admin?.country === "UAE" || admin?.country === "KSA" ? 9 : 10,
    max: admin?.country === "UAE" || admin?.country === "KSA" ? 12 : 10,
  });

  const loginSchema = Yup.object({
    number: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .min(limit.min, `Minimum ${limit.min} numbers is required !`)
      .max(limit.max, `Maximum ${limit.max} numbers is allowed!`)
      .typeError("field must be a number")
      .required("Number is requird"),
    name: Yup.string().required("Name is required"),
    termsAndCondition: Yup.bool().oneOf(
      [true],
      " Please select the checkbox before continuing."
    ),
  });

  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(false);
  const openDrawer = useCallback(() => setIsVisible(true), []);
  const closeDrawer = useCallback(() => {
    setIsVisible(false);
    closeprevious();
  }, []);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, action) => {
      if (admin?.isSendOtp) {
        handleLoginWithOtp(values);
      } else {
        handleLoginWithOutOtp(values);
      }
    },
  });

  const handleLoginWithOtp = async (values) => {
    const res = await loginWithPhone({
      phone: values.number,
      name: values.name,
      vendor: admin._id,
    });
    if (res.status === 200) {
      dispatch({ type: "LOGIN", payload: res.data });
      Cookies.set("user", JSON.stringify(res.data));
      returnSataus(res.status);
      closeprevious();
      toast.success("Login Successfull", {
        position: "bottom-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      await sendOtp({ number: values.number });
      openDrawer();
    }
  };

  const handleLoginWithOutOtp = async (values) => {
    const res = await loginWithOutOtp({
      phone: values.number,
      name: values.name,
      vendor: admin._id,
    });
    console.log(res);
    if (res.status === 200 && res.data.status) {
      dispatch({ type: "LOGIN", payload: res.data.user });
      Cookies.set("user", JSON.stringify(res.data.user));
      returnSataus(res.status);
      closeprevious();
      toast.success("Login Successfull", {
        position: "bottom-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            height: "auto",
            margin: "0rem 10px 2rem 10px",
            display: "flex",
            flexDirection: "column",
            gap: ".8rem",
          }}
        >
          <Typography sx={{ fontSize: "2.4rem", fontWeight: "400" }}>
            Login
          </Typography>
          {/* <Typography
            sx={{
              color: "rgba(144, 144, 144, 1)",
              fontSize: "1.5rem",
              fontWeight: "400",
            }}
          >
            Enter your phone number
          </Typography> */}
          <Typography
            sx={{
              fontSize: "1.5rem",
              fontWeight: "400",
            }}
          >
            Phone Number
          </Typography>
          <TextField
            variant="standard"
            placeholder="Enter Your Number"
            inputProps={{ style: { fontSize: "1.5rem" } }} // font size of input text
            FormHelperTextProps={{ style: { fontSize: "1.5rem" } }}
            name="number"
            value={formik.values.number}
            onChange={formik.handleChange}
            error={!!formik.touched.number && !!formik.errors.number}
            helperText={formik.touched.number && formik.errors.number}
          />

          <Typography
            sx={{
              fontSize: "1.5rem",
              fontWeight: "400",
            }}
          >
            Full Name
          </Typography>
          <TextField
            variant="standard"
            placeholder="Enter Your Name"
            FormHelperTextProps={{ style: { fontSize: "1.5rem" } }}
            inputProps={{ style: { fontSize: "1.5rem" } }} // font size of input text
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={!!formik.touched.name && !!formik.errors.name}
            helperText={formik.touched.name && formik.errors.name}
          />
          <Box>
            <Button
              id="continue-btn"
              style={{
                backgroundColor: "#FF2E2E",
              }}
              sx={{
                color: "#fff",
                height: "45px",
                width: "100%",
                borderRadius: "10px",
                fontSize: "1.3rem",
                fontWeight: "400",
              }}
              type="submit"
            >
              Continue
            </Button>
          </Box>
          <Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Checkbox
                value={formik.values.termsAndCondition}
                name="termsAndCondition"
                onChange={formik.handleChange}
                sx={{
                  color:
                    formik.touched.termsAndCondition &&
                    formik.errors.termsAndCondition &&
                    "#d32f2f",
                }}
              />
              <Typography
                sx={{
                  color: "rgba(144, 144, 144, 1)",
                  fontSize: "1rem",
                  fontWeight: "400",
                }}
              >
                By clicking, I accept the{" "}
                <Link
                  to="https://www.app.ouronlineservice.com/terms"
                  style={{ textDecoration: "none" }}
                >
                  <span style={{ color: "#000" }}>Terms & Conditions</span>
                </Link>{" "}
                &{" "}
                <Link
                  to="https://www.app.ouronlineservice.com/privacy"
                  style={{ textDecoration: "none" }}
                >
                  <span style={{ color: "#000" }}>Privacy Policy</span>
                </Link>
              </Typography>
            </Box>
            {formik.touched.termsAndCondition &&
              formik.errors.termsAndCondition && (
                <Typography
                  sx={{
                    color: "#d32f2f",
                    fontSize: "1.3rem",
                    fontWeight: "400",
                    ml: 1.5,
                    mt: 0,
                  }}
                >
                  {formik.errors.termsAndCondition}
                </Typography>
              )}
          </Box>

          <Drawer
            duration={250}
            hideScrollbars={true}
            onClose={closeDrawer}
            isVisible={isVisible}
          >
            <VerifyDrawer
              returnSataus={returnSataus}
              closeprevious={closeDrawer}
              data={formik.values}
            />
          </Drawer>
        </Box>
      </form>
    </>
  );
}
