import { Box } from "@mui/system";
import OrderCard from "../components/order/OrderCard";
import { makeStyles } from "@mui/styles";
import TopBar from "../components/common/TopBar";
import {
  cancelOrder,
  userOrderDetails,
  userOrderHistory,
} from "../sevice/order";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, CircularProgress, Modal, Typography } from "@mui/material";
import CancelOrderModal from "../components/order/CancelOrderModal";
import Lottie from "lottie-react";
import NoOrders from "../lottie/Nofav.json";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100dvh",
    width: "100vw",
    boxSizing: "border-box",
    padding: "2.5rem 1.5rem 2.5rem 1.5rem",
    backgroundColor: "#FAFAFA",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
}));
export default function Orders() {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state);
  const [loading, setLoading] = useState("spinner");
  const [allorders, setAllOrders] = useState([]);
  const [cancelOrderModal, setCancelOrderModal] = useState(false);
  const [cancelItemId, setCancelItemId] = useState(null);
  const cancelOrderModalOpen = () => setCancelOrderModal(true);
  const cancelOrderModalClose = () => setCancelOrderModal(false);
  const classes = useStyles();

  useEffect(() => {
    findAllOrders();
  }, []);

  const location = useLocation();
  const previousRoute = location?.state;

  const findAllOrders = async () => {
    if (previousRoute === "/") {
      const res = await userOrderDetails(user?._id);
      if (!res.length) {
        setLoading("lottie");
      } else {
        setLoading("item");
      }
      setAllOrders(res);
    } else {
      const res = await userOrderHistory(user?._id);
      if (!res.length) {
        setLoading("lottie");
      } else {
        setLoading("item");
      }
      setAllOrders(res);
    }
  };

  const cancelOrderFunc = async () => {
    const res = await cancelOrder(cancelItemId);
    if (res.status) {
      navigate("/");
    }
  };

  return (
    <Box className={classes.container}>
      <TopBar />
      {loading === "spinner" ? (
        <Box
          sx={{
            width: "100%",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      ) : loading === "item" ? (
        <>
          {allorders.reverse().map((item) => (
            <OrderCard
              key={item?._id}
              item={item}
              cancelOrderFunc={cancelOrderModalOpen}
              setCancelItemId={setCancelItemId}
            />
          ))}
        </>
      ) : (
        <>
          <Typography
            sx={{ fontSize: "1.6rem", textAlign: "center", color: "red" }}
          >
            No orders yet
          </Typography>
          <Box sx={{ width: "200px", height: "200px", alignSelf: "center" }}>
            <Lottie animationData={NoOrders} />
          </Box>
          <Box sx={{ alignSelf: "center" }}>
            <Button
              sx={{
                backgroundColor: "#FF2E2E",
                color: "#fff",
                width: "250px",
                height: "58px",
                fontSize: "1.7rem",
                fontWeight: "400",
                borderRadius: "20px",
                alignSelf: "center",
                mt: 2,
                "&:hover": {
                  backgroundColor: "#FF2E2E",
                },
              }}
              onClick={() => navigate("/")}
            >
              Continue Shopping
            </Button>
          </Box>
        </>
      )}
      <Box>
        <Modal
          open={cancelOrderModal}
          onClose={cancelOrderModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <CancelOrderModal
            handleClose={cancelOrderModalClose}
            cancelOrder={cancelOrderFunc}
          />
        </Modal>
      </Box>
    </Box>
  );
}
