import { Box, Typography } from "@mui/material";
import Lottie from "lottie-react";
import orderPlaced from "../../lottie/orderPlaced.json";
const orderModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70vw",
  height: "auto",
  bgcolor: "#fff",
  borderRadius: "20px",
  boxShadow: 24,
  p: "2rem 2rem",
  margin: "auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};
export default function OrderPlacedSuccessModal() {
  return (
    <Box sx={orderModalStyle}>
      <Box
        sx={{
          width: "50%",
          height: "40px",
          boxSizing: "border-box",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Lottie animationData={orderPlaced} />
      </Box>
      <Typography
        sx={{
          fontSize: "1.8rem",
          fontWeight: "500",
          marginInline: "auto",
          textAlign: "center",
          mb: 2,
          mt: 1,
        }}
      >
        Order Placed
      </Typography>
      <Typography
        sx={{
          fontSize: "1.3rem",
          fontWeight: "400",
          color: "#AFAFAF",
          marginInline: "auto",
          textAlign: "center",
        }}
      >
        Your order has been placed successfully
      </Typography>
      <Typography
        sx={{
          color: "#F04141",
          marginInline: "auto",
          mt: 2,
          fontSize: "1.6rem",
          fontWeight: "500",
        }}
      >
        OK
      </Typography>
    </Box>
  );
}
