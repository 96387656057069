import axios from "axios";
const host = process.env.REACT_APP_BACKEND_URL;
const adminId = process.env.REACT_APP_ADMIN_ID;

export const allProducts = async (categoryId = "") => {
  try {
    const { data } = await axios.get(
      `${host}/allProducts/${adminId}?offset=&group=${categoryId}&search=`
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
