import { Box, Typography } from "@mui/material";
import WifiOffIcon from "@mui/icons-material/WifiOff";
import React from "react";

export default function NetworkErrorPage() {
  return (
    <Box
      sx={{
        bgcolor: "#E9E9E9",
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxSizing: "border-box",
        paddingX: "40px",
      }}
    >
      <Box
        sx={{
          borderRadius: "8px",
          paddingX: "15px",
          paddingY: "25px",
          bgcolor: "#00000080",
          display: "flex",
          gap: "8px",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <WifiOffIcon sx={{ color: "#fff", fontSize: "4.7rem" }} />
        <Typography
          sx={{ fontSize: "28px", fontWeight: "500", fontFamily: "Inter", color: "#fff" }}
        >
          Oops!
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "400",
            fontFamily: "Inter",
            textAlign: "center",
            color: "#fff"
          }}
        >
          No Internet Connection Found Check your connection
        </Typography>
        <Box
          sx={{
            borderRadius: "11px",
            paddingX: "80px",
            paddingY: "10px",
            bgcolor: "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "15px",
            fontWeight: "400",
            fontFamily: "Inter",
          }}
          onClick={() => window.location.reload()}
        >
          Try Again
        </Box>
      </Box>
    </Box>
  );
}
