import { createSlice } from "@reduxjs/toolkit";

const initialState = localStorage.getItem("ourcartItems")
  ? JSON.parse(localStorage.getItem("ourcartItems"))
  : [];
const cartSlice = createSlice({
  name: "ourcart",
  initialState,
  reducers: {
    addToCart(state, action) {
      const existingIndex = state.findIndex(
        (item) => item.varientId._id === action.payload.varientId._id
      );
      if (existingIndex >= 0) {
        state[existingIndex] = {
          ...state[existingIndex],
          quantity: state[existingIndex].quantity + 1,
        };
      } else {
        state.push(action.payload);
      }
      localStorage.setItem("ourcartItems", JSON.stringify(state));
    },
    decreaseCart(state, action) {
      const existingIndex = state.findIndex(
        (item) => item.varientId._id === action.payload.varientId._id
      );
      if (state[existingIndex].quantity > 1) {
        state[existingIndex].quantity -= 1;
      } else if (state[existingIndex].quantity === 1) {
        state.splice(existingIndex, 1);
      }
      localStorage.setItem("ourcartItems", JSON.stringify(state));
    },
    removeFromCart(state, action) {
      const existingIndex = state.findIndex(
        (item) => item.varientId._id === action.payload.varientId._id
      );
      if (existingIndex >= 0) {
        state.splice(existingIndex, 1);
        localStorage.setItem("ourcartItems", JSON.stringify(state));
      }
    },
    clearCart(state, action) {
      state.splice(0, state.length);
      localStorage.setItem("ourcartItems", JSON.stringify(state));
    },
  },
});

export const { addToCart, decreaseCart, removeFromCart, clearCart } =
  cartSlice.actions;

export default cartSlice.reducer;
