import React, { useEffect } from "react";
import { useGeolocated } from "react-geolocated";

const CurrentLocation = ({ setSelectedPlace }) => {
  const { coords } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
  });
  useEffect(() => {
    if (coords) {
      setSelectedPlace({
        lat: coords?.latitude,
        lng: coords?.longitude,
      });
    }
  }, [coords]);
  return <></>;
};

export default CurrentLocation;
