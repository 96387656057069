import { Box, Skeleton, Typography } from "@mui/material";

export default function CategoryCardSkeleton({ index }) {
  return (
    <Box
      key={index}
      sx={{
        width: "115px",
        height: "150px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "1rem",
        flexShrink: 0,
        boxSizing: "border-box",
        textAlign: "center",
      }}
    >
      <Skeleton variant="circular" width={"90px"} height={"90px"} />
      <Typography
        sx={{
          fontSize: "1.3rem",
          fontWeight: "400",
        }}
      ></Typography>
    </Box>
  );
}
