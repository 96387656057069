import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Typography,
  Button,
  Box,
  RadioGroup,
  Radio,
  FormControl,
  Modal,
  CircularProgress,
} from "@mui/material";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import { deleteAddress, getAllAddress } from "../../sevice/address";
import Cookies from "js-cookie";
import HomeIcon from "@mui/icons-material/Home";
import LocalDiningIcon from "@mui/icons-material/LocalDining";
import PlaceIcon from "@mui/icons-material/Place";
import RemoveItem from "../common/RemoveItem";
import addressLottie from "../../lottie/address.json";
import Lottie from "lottie-react";
import { toast } from "react-toastify";

export default function AddressCard() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState("spinner");
  const { address } = useSelector((state) => state);
  const [allAddress, setAllAddress] = useState([]);
  const { user } = useSelector((state) => state);
  const dispatch = useDispatch();
  const location = useLocation();
  const previousRoute = location?.state;
  console.log(previousRoute);
  useEffect(() => {
    findAddress();
  }, []);
  const findAddress = async () => {
    const res = await getAllAddress(user._id);
    if (!res.length) {
      setLoading("lottie");
    } else {
      setLoading("item");
    }
    setAllAddress(res);
    if (!res.length) {
      dispatch({ type: "ADDRESS", payload: null });
      Cookies.set("address", JSON.stringify(null));
    } else if (previousRoute === "/map") {
      dispatch({ type: "ADDRESS", payload: res[0] });
      Cookies.set("address", JSON.stringify(res[0]));
    } else if (!address && res.length) {
      dispatch({ type: "ADDRESS", payload: res[0] });
      Cookies.set("address", JSON.stringify(res[0]));
    }
  };
  const handleChange = (event) => {
    dispatch({ type: "ADDRESS", payload: event });
    Cookies.set("address", JSON.stringify(event));
  };

  const [itemId, setItemId] = useState(null);

  const [removeAddrModalOpen, setRemoveAddrModalOpen] = useState(false);
  const removeAddrModalhandleOpen = () => setRemoveAddrModalOpen(true);
  const removeAddrModalhandleClose = () => setRemoveAddrModalOpen(false);

  const deleteAddressFunc = async () => {
    const res = await deleteAddress(itemId);
    if (res.status) {
      toast.success(res.message, {
        position: "bottom-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      findAddress();
    }
  };
  const editAddress = async (item) => {
    navigate("/map", { state: { item: item } });
  };
  return (
    <>
      {loading === "spinner" ? (
        <Box
          sx={{
            width: "100%",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      ) : loading === "item" ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Typography sx={{ fontSize: "1.6rem", px: "20px" }}>
            {allAddress.length ? "Saved Address" : "No Saved Address"}
          </Typography>
          <FormControl sx={{ mb: allAddress.length > 2 && "80px" }}>
            <RadioGroup
              overlay
              name="subscription"
              defaultValue="Lite"
              value={address?._id}
            >
              <Box
                sx={{ display: "flex", gap: "20px", flexDirection: "column" }}
              >
                {allAddress?.map((item) => (
                  <Box
                    key={item._id}
                    sx={{
                      display: "flex",
                      gap: "15px",
                      p: "20px",
                      background: "#FFFFFF",
                      justifyContent: "space-between",
                      alignItems: "normal",
                    }}
                  >
                    <Box sx={{ display: "flex", gap: "15px" }}>
                      {item.type === "Home" ? (
                        <HomeIcon
                          sx={{ height: "20px", width: "20px", mt: "2px" }}
                        />
                      ) : item.type === "Work" ? (
                        <WorkOutlineOutlinedIcon
                          sx={{ height: "20px", width: "20px", mt: "2px" }}
                        />
                      ) : item.type === "Hotel" ? (
                        <LocalDiningIcon
                          sx={{ height: "20px", width: "20px", mt: "2px" }}
                        />
                      ) : (
                        <PlaceIcon
                          sx={{ height: "20px", width: "20px", mt: "2px" }}
                        />
                      )}
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                        }}
                      >
                        <Typography sx={{ fontSize: "1.6rem" }}>
                          {item.type}
                        </Typography>
                        <Typography
                          sx={{ fontSize: "1.4rem", color: "#A4A4A4" }}
                        >
                          {item.address}
                        </Typography>
                        <Box>
                          <Button
                            variant="text"
                            sx={{
                              height: "10px",
                              width: "23px",
                              fontSize: "1.3rem",
                              color: "#FF2E2E",
                            }}
                            onClick={() => editAddress(item)}
                          >
                            Edit
                          </Button>
                          <Button
                            variant="text"
                            sx={{
                              height: "10px",
                              width: "23px",
                              fontSize: "1.3rem",
                              color: "#FF2E2E",
                            }}
                            onClick={() => {
                              setItemId(item._id);
                              removeAddrModalhandleOpen();
                            }}
                          >
                            Delete
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "self-start" }}>
                      <Radio
                        value={item?._id}
                        sx={{
                          color: "#000",
                        }}
                        onClick={() => handleChange(item)}
                      />
                    </Box>
                  </Box>
                ))}
              </Box>
            </RadioGroup>
          </FormControl>
          {/*  */}
          <Box>
            <Modal
              open={removeAddrModalOpen}
              onClose={removeAddrModalhandleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <RemoveItem
                removeItemModalhandleClose={removeAddrModalhandleClose}
                handleDeleteFavProduct={deleteAddressFunc}
                head1={"Address"}
                message={"Are you sure to delete address"}
              />
            </Modal>
          </Box>
          {/* <Box sx={{ height: "60px" }}></Box> */}
        </Box>
      ) : (
        <Box sx={{ mt: 2 }}>
          <Typography
            sx={{
              fontSize: "1.6rem",
              px: "20px",
              textAlign: "center",
              color: "red",
            }}
          >
            No Saved Address
          </Typography>
          <Box
            sx={{
              width: "180px",
              height: "180px",
              alignSelf: "center",
              mx: "auto",
              mt: 2,
            }}
          >
            <Lottie animationData={addressLottie} />
          </Box>
        </Box>
      )}
    </>
  );
}
