import { Box, Typography } from "@mui/material";
import { StandaloneSearchBox, LoadScript } from "@react-google-maps/api";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useRef, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "../../App.css";
import CurrentLocation from "./CurrentLocation";

const MapSearch = ({ setSelectedPlace, setIsmap, setLoactionName }) => {
  const [currentLoc, setCurrentLoc] = useState(null);
  const inputRef = useRef();
  const handlepace = () => {
    const [place] = inputRef.current.getPlaces();
    if (place) {
      let lat = Number(place.geometry.location.lat());
      let lng = Number(place.geometry.location.lng());
      setSelectedPlace({
        lat: lat,
        lng: lng,
      });
      console.log(place);
      setLoactionName({
        city: place?.address_components[0]?.long_name,
        state: place?.address_components[2]?.long_name,
        country: place?.address_components[3]?.long_name,
        sublocality: place?.address_components[0]?.long_name,
        address: place?.formatted_address,
      });
      setIsmap("map");
    }
  };
  const selectCurrentLocation = () => {
    setSelectedPlace(currentLoc);
    setIsmap("map");
  };
  return (
    <Box
      sx={{
        py: "20px",
        px: "10px",
        display: "flex",
        flexDirection: "column",
        gap: "5px",
      }}
    >
      <Box
        sx={{
          height: "35px",
          width: "35px",
          display: "flex",
          borderRadius: "50%",
          justifyContent: "center",
          alignItems: "center",
          mb: "10px",
        }}
      >
        <ArrowBackIcon
          sx={{
            fontSize: "3rem",
          }}
          onClick={() => setIsmap("map")}
        />
      </Box>
      <Box>
        <Typography sx={{ fontSize: "2rem", mx: "5px" }}>
          Search Location
        </Typography>
      </Box>
      <LoadScript
        googleMapsApiKey="AIzaSyCEQcqeS9ecfcP1ux0aJofX7H3grA96v8E"
        libraries={["places"]}
      >
        <StandaloneSearchBox
          onLoad={(ref) => (inputRef.current = ref)}
          onPlacesChanged={handlepace}
        >
          <input
            type="text"
            placeholder=""
            autoFocus
            style={{
              border: `1px solid #D9D9D9`,
              width: `83%`,
              height: `50px`,
              padding: `0 12px`,
              borderRadius: `3px`,
              fontSize: `14px`,
              outline: `none`,
              textOverflow: `ellipses`,
              position: "absolute",
              // margin: "auto",
            }}
          />
        </StandaloneSearchBox>
      </LoadScript>
      <Box
        sx={{
          marginTop: "60px",
          display: "flex",
          gap: "5px",
          alignItems: "center",
        }}
        onClick={selectCurrentLocation}
      >
        <LocationOnIcon sx={{ color: "red", width: "20px", height: "20px" }} />
        <Typography sx={{ fontSize: "1.5rem", color: "red" }}>
          {" "}
          Use my current location
          <CurrentLocation setSelectedPlace={setCurrentLoc} />
        </Typography>
      </Box>
    </Box>
  );
};
export default MapSearch;
