import { combineReducers } from "redux";
import { UserReducer } from "./reducers/UserReducer";
import { AdminReducer } from "./reducers/AdminReducer";
import { AddressReducer } from "./reducers/AddressReducer";
import { SomeOneContactReducer } from "./reducers/SomeOneContactReducer";
import { ProductsReducer } from "./reducers/ProductsReducer";
import cartReducer from "./cartslice";
import favourReducer from "./favouriteslice";
import { OurMainBannersReducer } from "./reducers/MainBanner";
import { OurCategoriesReducer } from "./reducers/CategoriesReducer";
import { InstructionReducer } from "./reducers/Instruction";

const rootReducer = combineReducers({
  user: UserReducer,
  admin: AdminReducer,
  address: AddressReducer,
  someonecontact: SomeOneContactReducer,
  ourproductsdata: ProductsReducer,
  ourcart: cartReducer,
  ourmainbanners: OurMainBannersReducer,
  ourcategories: OurCategoriesReducer,
  ourfavourites: favourReducer,
  ourInstruction: InstructionReducer,
});

export default rootReducer;
