import React from "react";
import TopBar from "../common/TopBar";
import { Box, Typography } from "@mui/material";
import Drawer from "react-bottom-drawer";
import { getDistanceFromLatLonInKm } from "./Location";
import { useDispatch, useSelector } from "react-redux";
import { addAddress, editAddress } from "../../sevice/address";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { useCallback } from "react";
import { useState } from "react";
import LoginPromptDrawer from "../../components/login/LoginPrompt";

export default function AddressDetails({
  addressData,
  setAddressData,
  adminLatAndLog,
}) {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state);

  const [isVisible, setIsVisible] = useState(false);
  const openDrawer = useCallback(() => setIsVisible(true), []);
  const closeDrawer = useCallback(() => setIsVisible(false), []);
  const changeType = (type) => {
    setAddressData({ ...addressData, type: type });
  };
  const saveAddress = async () => {
    const distance = getDistanceFromLatLonInKm(
      adminLatAndLog.lat,
      adminLatAndLog.lng,
      addressData.latitude,
      addressData.longitude
    );
    const res = await addAddress({ ...addressData, distance: distance });
    if (res.status === 200) {
      toast.success(res.data.message, {
        position: "bottom-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      dispatch({ type: "ADDRESS", payload: res.data.data });
      Cookies.set("address", JSON.stringify(res.data.data));
      navigate(-1);
    }
  };

  const item = location?.state?.item;
  const updateAddress = async () => {
    const distance = getDistanceFromLatLonInKm(
      adminLatAndLog.lat,
      adminLatAndLog.lng,
      addressData.latitude,
      addressData.longitude
    );

    const res = await editAddress(item._id, {
      ...addressData,
      distance: distance,
    });
    if (res.status) {
      toast.success(res.message, {
        position: "bottom-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      navigate("/address", { replace: true }, { state: "/map" });
    }
  };

  const returnSataus = (status) => {
    if (status === 200) {
    }
  };
  return (
    <Box sx={{ my: "10px" }}>
      <TopBar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          mx: "15px",
        }}
      >
        <Box>
          <Typography sx={{ fontSize: "1.5rem" }}>
            Enter address details
          </Typography>
          <Typography sx={{ fontSize: "1.5rem", color: "#979797", mt: "10px" }}>
            Save address as
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: "5px" }}>
          <Box
            sx={{
              border:
                addressData.type === "Home"
                  ? "1px solid red"
                  : "1px solid #D9D9D9",
              px: "12px",
              py: "5px",
              borderRadius: "5px",
            }}
            onClick={() => changeType("Home")}
          >
            <Typography sx={{ fontSize: "1.2rem" }}>Home</Typography>
          </Box>
          <Box
            sx={{
              border: "1px solid red",
              px: "12px",
              border:
                addressData.type === "Work"
                  ? "1px solid red"
                  : "1px solid #D9D9D9",
              py: "5px",
              borderRadius: "5px",
            }}
            onClick={() => changeType("Work")}
          >
            <Typography sx={{ fontSize: "1.2rem" }}>Work</Typography>
          </Box>{" "}
          <Box
            sx={{
              border: "1px solid red",
              px: "12px",
              py: "5px",
              border:
                addressData.type === "Hotel"
                  ? "1px solid red"
                  : "1px solid #D9D9D9",
              borderRadius: "5px",
            }}
            onClick={() => changeType("Hotel")}
          >
            <Typography sx={{ fontSize: "1.2rem" }}>Hotel</Typography>
          </Box>{" "}
          <Box
            sx={{
              border: "1px solid red",
              px: "12px",
              py: "5px",
              borderRadius: "5px",
              border:
                addressData.type === "Other"
                  ? "1px solid red"
                  : "1px solid #D9D9D9",
            }}
            onClick={() => changeType("Other")}
          >
            <Typography sx={{ fontSize: "1.2rem" }}>Other</Typography>
          </Box>
        </Box>

        <Box>
          <input
            type="text"
            placeholder="Complete address"
            autoFocus
            style={{
              border: `1px solid #D9D9D9`,
              width: `100%`,
              height: `50px`,
              borderRadius: `3px`,
              fontSize: `14px`,
              outline: `none`,
              boxSizing: "border-box",
              padding: "10px",
              margin: "auto",
            }}
            value={addressData.address}
            onChange={(e) => {
              setAddressData({ ...addressData, address: e.target.value });
            }}
          />
        </Box>
        <Box>
          <input
            type="text"
            placeholder="Floor (optional)"
            autoFocus
            style={{
              border: `1px solid #D9D9D9`,
              width: `100%`,
              height: `50px`,
              borderRadius: `3px`,
              fontSize: `14px`,
              outline: `none`,
              boxSizing: "border-box",
              padding: "10px",
              margin: "auto",
            }}
            value={addressData.floor}
            onChange={(e) => {
              setAddressData({ ...addressData, floor: e.target.value });
            }}
          />
        </Box>
        <Box>
          <input
            type="text"
            placeholder="Nearby landmark (optional)"
            autoFocus
            style={{
              border: `1px solid #D9D9D9`,
              width: `100%`,
              height: `50px`,
              borderRadius: `3px`,
              fontSize: `14px`,
              outline: `none`,
              boxSizing: "border-box",
              padding: "10px",
              margin: "auto",
            }}
            value={addressData.landMark}
            onChange={(e) => {
              setAddressData({ ...addressData, landMark: e.target.value });
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            backgroundColor: "#FF2E2E",
            borderRadius: "5px",
            color: "#FFFFFF",
            position: "fixed",
            m: "10px",
            bottom: "0",
            left: "0",
            right: "0",
            height: "60px",
          }}
          onClick={() => {
            if (!user) {
              console.log("object");
              openDrawer();
            } else {
              if (item) {
                updateAddress();
              } else {
                saveAddress();
              }
            }
            // !user ? openDrawer() : item ? updateAddress() : saveAddress();
          }}
        >
          <Typography sx={{ fontSize: "1.6rem" }}>
            {item ? "Update Address" : "Save Address"}
          </Typography>
        </Box>
      </Box>
      <Drawer
        duration={250}
        hideScrollbars={true}
        onClose={closeDrawer}
        isVisible={isVisible}
      >
        <LoginPromptDrawer
          returnSataus={returnSataus}
          closeprevious={closeDrawer}
        />
      </Drawer>
    </Box>
  );
}
