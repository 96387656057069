import {
  Box,
  Button,
  Divider,
  FormControl,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import HomeIcon from "@mui/icons-material/Home";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import LocalDiningIcon from "@mui/icons-material/LocalDining";
import PlaceIcon from "@mui/icons-material/Place";
import { useDispatch, useSelector } from "react-redux";
import { getAllAddress } from "../../sevice/address";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

export default function SelectAddress({ closeDrawer }) {
  const navigate = useNavigate();
  const { address } = useSelector((state) => state);
  const { user } = useSelector((state) => state);
  const dispatch = useDispatch();

  const handleChange = (event) => {
    dispatch({ type: "ADDRESS", payload: event });
    Cookies.set("address", JSON.stringify(event));
  };

  const [allAddress, setAllAddress] = useState([]);
  const findallAddress = async () => {
    const res = await getAllAddress(user?._id);
    setAllAddress(res);
  };
  useEffect(() => {
    findallAddress();
  }, []);

  return (
    <Box
      sx={{
        height: "auto",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        margin: "0rem 10px 2rem 10px",
        height: "100%",
      }}
    >
      <Typography sx={{ fontSize: "1.5rem", fontWeight: "500" }}>
        Select an address
      </Typography>
      <Divider />
      <Box
        sx={{ display: "flex", gap: "3rem", alignItems: "center" }}
        onClick={() => navigate("/map")}
      >
        <AddIcon sx={{ fontSize: "3rem" }} />
        <Typography sx={{ fontSize: "1.5rem", fontWeight: "200" }}>
          Add new address
        </Typography>
      </Box>
      <Divider />
      <Typography sx={{ fontSize: "1.5rem", fontWeight: "200" }}>
        Saved addresses
      </Typography>
      <FormControl>
        <RadioGroup
          overlay
          name="subscription"
          defaultValue="Lite"
          value={address?._id}
        >
          <Box sx={{ height: "35vh", overflowY: "scroll" }}>
            {allAddress?.map((item) => (
              <Box
                key={item._id}
                sx={{
                  display: "flex",
                  gap: "1rem",
                  padding: "1.5rem 1rem",
                  boxShadow: "0px 8px 3px -8px #111",
                  justifyContent: "space-between",
                }}
              >
                {" "}
                <Box sx={{ display: "flex", gap: "15px" }}>
                  {item.type === "Home" ? (
                    <HomeIcon
                      sx={{ height: "20px", width: "20px", mt: "2px" }}
                    />
                  ) : item.type === "Work" ? (
                    <WorkOutlineOutlinedIcon
                      sx={{ height: "20px", width: "20px", mt: "2px" }}
                    />
                  ) : item.type === "Hotel" ? (
                    <LocalDiningIcon
                      sx={{ height: "20px", width: "20px", mt: "2px" }}
                    />
                  ) : (
                    <PlaceIcon
                      sx={{ height: "20px", width: "20px", mt: "2px" }}
                    />
                  )}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.5rem",
                    }}
                  >
                    <Typography sx={{ fontSize: "1.5rem", fontWeight: "400" }}>
                      {item.type}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "1.4rem",
                        fontWeight: "400",
                        color: "#909090",
                      }}
                    >
                      {item.address}
                    </Typography>
                    <Box sx={{ display: "flex", gap: "1.5rem" }}>
                      <Typography
                        sx={{
                          fontSize: "1.5rem",
                          fontWeight: "400",
                          color: "red",
                        }}
                      >
                        Edit
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "1.5rem",
                          fontWeight: "400",
                          color: "red",
                        }}
                      >
                        Delete
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "1.5rem",
                          fontWeight: "400",
                          color: "red",
                        }}
                      >
                        Share
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", alignItems: "self-start" }}>
                  <Radio
                    value={item?._id}
                    sx={{
                      color: "#000",
                      "& .MuiSvgIcon-root": {
                        fontSize: "2rem",
                      },
                    }}
                    onClick={() => handleChange(item)}
                  />
                </Box>
              </Box>
            ))}
          </Box>
        </RadioGroup>
      </FormControl>
      <Button
        sx={{
          backgroundColor: "#FF2E2E",
          color: "#fff",
          height: "45px",
          borderRadius: "10px",
          fontSize: "1.3rem",
          fontWeight: "400",
          mt: 1,
          "&:hover": {
            backgroundColor: "#FF2E2E",
          },
        }}
        type="submit"
        onClick={closeDrawer}
      >
        Proceed with this address
      </Button>
    </Box>
  );
}
