import { useCallback, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import gift from "../assets/gift.png";
import Drawer from "react-bottom-drawer";
import DrawerContent from "../components/gift/AddContact";
import AddrDrawerContent from "../components/gift/SelectAddress";
import ContactEmergencyIcon from "@mui/icons-material/ContactEmergency";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100dvh",
    width: "100vw",
    boxSizing: "border-box",
    backgroundColor: "rgba(248, 248, 248, 1)",
    display: "flex",
    flexDirection: "column",
  },
}));

export default function GiftInformation() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { address } = useSelector((state) => state);
  const { someonecontact } = useSelector((state) => state);
  const [isVisible, setIsVisible] = useState(false);
  const openDrawer = useCallback(() => setIsVisible(true), []);
  const closeDrawer = useCallback(() => setIsVisible(false), []);
  const [someOneData, setSomeOneData] = useState(someonecontact);
  const [addrIsVisible, setAddrIsVisible] = useState(false);
  const openAddrDrawer = useCallback(() => setAddrIsVisible(true), []);
  const closeAddrDrawer = useCallback(() => setAddrIsVisible(false), []);

  const classes = useStyles();
  const proceedTOsomeOne = () => {
    dispatch({ type: "SOMEONECONTACTDATA", payload: someOneData });
    navigate("/cart", { state: "/giftinginfo" });
  };
  const goBack = () => {
    dispatch({ type: "SOMEONECONTACTDATA", payload: null });
    navigate("/cart");
  };
  return (
    <Box className={classes.container}>
      <ArrowBackIcon
        sx={{
          fontSize: "3rem",
          position: "absolute",
          left: 10,
          top: 20,
          color: "#fff",
        }}
        onClick={goBack}
      />

      <img src={gift} alt="gift" style={{ maxHeight: "40vh" }} />
      <Typography
        sx={{
          fontSize: "1.8rem",
          fontWeight: "500",
          alignSelf: "center",
          my: 2,
        }}
      >
        Gifting information
      </Typography>
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          bgcolor: "#fff",
          // height: "86px",
          width: "90vw",
          alignSelf: "center",
          borderRadius: "30px",
          boxSizing: "border-box",
          p: "1rem 2rem",
        }}
        onClick={() => openDrawer()}
      >
        <Box
          sx={{
            border: "3px dotted rgba(248, 248, 248, 1)",
            p: 1,
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ContactEmergencyIcon sx={{ fontSize: "3.5rem" }} />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography sx={{ fontSize: "1.3rem", fontWeight: "400" }}>
            Choose contact
          </Typography>
          <Typography sx={{ fontSize: "1.3rem", fontWeight: "400" }}>
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: "400",
                color: "rgba(126, 126, 126, 1)",
              }}
            >
              We will inform them once your gift is on the way
            </Typography>
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          bgcolor: "#fff",
          // height: "86px",
          width: "90vw",
          alignSelf: "center",
          borderRadius: "30px",
          boxSizing: "border-box",
          p: "1rem 2rem",
          my: 2,
        }}
        onClick={() => openAddrDrawer()}
      >
        <Box
          sx={{
            border: "3px dotted rgba(248, 248, 248, 1)",
            borderRadius: "50%",
            p: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LocationOnIcon sx={{ fontSize: "3.5rem" }} />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography sx={{ fontSize: "1.3rem", fontWeight: "400" }}>
            Choose address
          </Typography>
          <Typography sx={{ fontSize: "1.3rem", fontWeight: "400" }}>
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: "400",
                color: "rgba(126, 126, 126, 1)",
              }}
            >
              We will inform them once your gift is on the way
            </Typography>
          </Typography>
        </Box>
      </Box>
      {!address || !someOneData ? (
        <Button
          sx={{
            backgroundColor: "#808080",
            color: "#fff",
            height: "50px",
            borderRadius: "10px",
            fontSize: "1.3rem",
            fontWeight: "400",
            position: "absolute",
            bottom: 0,
            width: "90vw",
            m: 2,
            "&:hover": {
              backgroundColor: "#808080",
            },
          }}
        >
          Proceed
        </Button>
      ) : (
        <Button
          sx={{
            backgroundColor: "#FF2E2E",
            color: "#fff",
            height: "50px",
            borderRadius: "10px",
            fontSize: "1.3rem",
            fontWeight: "400",
            position: "absolute",
            bottom: 0,
            width: "90vw",
            m: 2,
            "&:hover": {
              backgroundColor: "#FF2E2E",
            },
          }}
          onClick={proceedTOsomeOne}
        >
          Proceed
        </Button>
      )}
      <Drawer
        duration={250}
        hideScrollbars={true}
        onClose={closeDrawer}
        isVisible={isVisible}
      >
        <DrawerContent
          closeDrawer={closeDrawer}
          someOneData={someOneData}
          setSomeOneData={setSomeOneData}
        />
      </Drawer>
      <Drawer
        duration={250}
        hideScrollbars={true}
        onClose={closeAddrDrawer}
        isVisible={addrIsVisible}
      >
        <AddrDrawerContent closeDrawer={closeAddrDrawer} />
      </Drawer>
    </Box>
  );
}
