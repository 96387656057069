import axios from "axios";
import Geocode from "react-geocode";
Geocode.setApiKey("AIzaSyCEQcqeS9ecfcP1ux0aJofX7H3grA96v8E");
Geocode.setLanguage("en");
Geocode.setRegion("es");
Geocode.setLocationType("ROOFTOP");
Geocode.enableDebug();

export default function findLocatio(lat, lon) {
  let city, state, country, sublocality;
  let address;
  return new Promise((resolve, reject) => {
    Geocode.fromLatLng(Number(Number(lat)), Number(Number(lon))).then(
      (response) => {
        address = response?.results[0]?.formatted_address;
        for (
          let i = 0;
          i < response?.results[0]?.address_components?.length;
          i++
        ) {
          for (
            let j = 0;
            j < response?.results[0]?.address_components[i]?.types?.length;
            j++
          ) {
            switch (response?.results[0]?.address_components[i]?.types[j]) {
              case "locality":
                city = response?.results[0]?.address_components[i]?.long_name;
                break;
              case "administrative_area_level_1":
                state = response?.results[0]?.address_components[i]?.long_name;
                break;
              case "sublocality":
                sublocality =
                  response?.results[0]?.address_components[i]?.long_name;
                break;
              case "country":
                country =
                  response?.results[0]?.address_components[i]?.long_name;
                break;
            }
          }
        }
        const location = {
          city,
          state,
          country,
          sublocality,
          address,
        };
        resolve(location);
      },
      (error) => {
        console.error(error);
      }
    );
  });
}

export function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2 - lat1); // deg2rad below
  var dLon = deg2rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  return d;
}

function deg2rad(deg) {
  return deg * (Math.PI / 180);
}

// Geocode the address
export async function addressusingLatitudeAndLongitude(address) {
  try {
    const { data } = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyCEQcqeS9ecfcP1ux0aJofX7H3grA96v8E`
    );
    return data.results[0].geometry.location;
  } catch (error) {
    return error;
  }
}
