
export function OurCategoriesReducer(
  state = sessionStorage.getItem("ourcategories")
    ? JSON.parse(sessionStorage.getItem("ourcategories"))
    : [],
  action
) {
  switch (action.type) {
    case "OURCATEGORIES":
      return action.payload;
    default:
      return state;
  }
}
