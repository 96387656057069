import { createSlice } from "@reduxjs/toolkit";

const initialState = localStorage.getItem("ourfavouriteItems")
  ? JSON.parse(localStorage.getItem("ourfavouriteItems"))
  : [];
const favouriteSlice = createSlice({
  name: "ourfavourites",
  initialState,
  reducers: {
    addToFavourites(state, action) {
      const existingIndex = state.findIndex((item) => item === action.payload);
      if (existingIndex >= 0) {
        state.splice(existingIndex, 1);
      } else {
        state.push(action.payload);
      }
      localStorage.setItem("ourfavouriteItems", JSON.stringify(state));
    },
    removeItemFromFavourites(state, action) {
      const existingIndex = state.findIndex((item) => item === action.payload);
      console.log(existingIndex);
      state.splice(existingIndex, 1);
      localStorage.setItem("ourfavouriteItems", JSON.stringify(state));
    },
  },
});

export const { addToFavourites, removeItemFromFavourites } =
  favouriteSlice.actions;

export default favouriteSlice.reducer;
