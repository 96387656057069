import { useEffect, useState } from "react";
import MapCom from "../components/map/MapCom";
import { LoadScript } from "@react-google-maps/api";
import { Box, Button, Typography } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MapSearch from "../components/map/MapSearch";
import findLocatio, {
  addressusingLatitudeAndLongitude,
} from "../components/map/Location";
import CurrentLocation from "../components/map/CurrentLocation";
import AddressDetails from "../components/map/AddressDetails";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
const Map = () => {
  const location = useLocation();
  const { user } = useSelector((state) => state);
  const { admin } = useSelector((state) => state);
  const [currentLoc, setCurrentLoc] = useState(null);
  const [isMap, setIsmap] = useState("map");
  const [adminLatAndLog, setAdminLatAndLog] = useState({
    lat: 11.255,
    lng: 75.8282,
  });
  const [selectedPlace, setSelectedPlace] = useState({
    lat: 11.255,
    lng: 75.8282,
  });
  const [addressData, setAddressData] = useState({
    vendor: user?.vendor,
    user: user?._id,
    address: currentLoc?.address,
    type: "Home",
    floor: "",
    landMark: "",
    latitude: selectedPlace?.lat,
    longitude: selectedPlace?.lng,
    distance: "",
  });
  useEffect(() => {
    setAddressData({
      ...addressData,
      address: currentLoc?.address,
      latitude: selectedPlace.lat,
      longitude: selectedPlace.lng,
    });
  }, [currentLoc, selectedPlace]);
  useEffect(() => {
    findLocationUsingLatAndLog();
  }, [selectedPlace]);
  useEffect(() => {
    findAdminLatAndLog();
  }, []);
  async function findAdminLatAndLog() {
    const latAndLng = await addressusingLatitudeAndLongitude(
      admin.businessAddress
    );
    setAdminLatAndLog(latAndLng);
  }
  const findLocationUsingLatAndLog = async () => {
    if (selectedPlace) {
      const res = await findLocatio(selectedPlace.lat, selectedPlace.lng);
      setCurrentLoc(res);
    }
  };

  const item = location?.state?.item;
  useEffect(() => {
    if (item) {
      setSelectedPlace({
        lat: Number(item.latitude),
        lng: Number(item.longitude),
      });
      setAddressData({
        vendor: user?.vendor,
        user: user?._id,
        address: item?.address,
        type: item.type,
        floor: item.floor,
        landMark: item.landMark,
        latitude: Number(item.latitude),
        longitude: Number(item.longitude),
        distance: item.distance,
      });
    }
  }, []);

  return (
    <Box>
      {!item && <CurrentLocation setSelectedPlace={setSelectedPlace} />}
      {isMap === "map" ? (
        <Box>
          <Box>
            <LoadScript
              googleMapsApiKey="AIzaSyCEQcqeS9ecfcP1ux0aJofX7H3grA96v8E"
              libraries={["drawing", "places"]}
            >
              <MapCom
                selectedPlace={selectedPlace}
                setSelectedPlace={setSelectedPlace}
                adminLatAndLog={adminLatAndLog}
                admin={admin}
              />
            </LoadScript>
          </Box>
          <Box
            sx={{
              maxHeight: window.innerHeight >= 700 ? "24dvh" : "27dvh",
              borderRadius: "5px",
              p: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            <Typography sx={{ fontSize: "2rem" }}>
              Select Delivery Location
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <LocationOnIcon
                  sx={{ height: "25px", width: "25px", color: "red" }}
                />
                <Typography sx={{ fontSize: "2rem" }}>
                  {currentLoc?.sublocality
                    ? currentLoc?.sublocality
                    : currentLoc
                    ? currentLoc?.address.split(",")[1]
                    : ""}
                </Typography>
              </Box>
              <Button
                variant="outlined"
                sx={{
                  borderColor: "#F40000",
                  color: "Black",
                  background: "#FBE7E780",
                  "&:hover": {
                    background: "#FBE7E780",
                  },
                }}
                onClick={() => setIsmap("search")}
              >
                Change
              </Button>
            </Box>
            <Box>
              <Typography sx={{ fontSize: "1.3rem" }}>
                {currentLoc && currentLoc?.address}
              </Typography>
            </Box>
            <Box>
              <Button
                variant="contained"
                sx={{
                  width: "100%",
                  background: "#FF2E2E",
                  height: "40px",
                  "&:hover": {
                    background: "#FF2E2E",
                  },
                }}
                onClick={() => setIsmap("details")}
              >
                Confirm Location
              </Button>
            </Box>
          </Box>
        </Box>
      ) : isMap === "search" ? (
        <MapSearch
          setSelectedPlace={setSelectedPlace}
          setIsmap={setIsmap}
          setLoactionName={setCurrentLoc}
        />
      ) : (
        <AddressDetails
          addressData={addressData}
          setAddressData={setAddressData}
          user={user}
          admin={admin}
          adminLatAndLog={adminLatAndLog}
        />
      )}
    </Box>
  );
};
export default Map;
