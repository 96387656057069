import { Box, Typography } from "@mui/material";
import ViewQuiltOutlinedIcon from "@mui/icons-material/ViewQuiltOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { Link } from "react-router-dom";

export default function OrderFavouriteAndAddressTabs() {
  return (
    <Box
      sx={{
        background: "#FFFFFF",
        p: "2rem",
        display: "flex",
        gap: "20px",
        flexDirection: "column",
      }}
    >
      <Link to={`/orders`} style={{ textDecoration: "none", color: "#000" }}>
        <Box
          sx={{
            display: "flex",
            gap: "8px",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
            <Box
              sx={{
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                bgcolor: "#D9D9D9",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ViewQuiltOutlinedIcon sx={{ color: "#9C9C9C" }} />
            </Box>
            <Typography sx={{ fontSize: "1.5rem", fontWeight: 400 }}>
              Your Orders
            </Typography>
          </Box>
          <ArrowForwardIosOutlinedIcon />
        </Box>
      </Link>
      <Link to={`/favourite`} style={{ textDecoration: "none", color: "#000" }}>
        <Box
          sx={{
            display: "flex",
            gap: "8px",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
            <Box
              sx={{
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                bgcolor: "#D9D9D9",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FavoriteBorderIcon sx={{ color: "#9C9C9C" }} />
            </Box>
            <Typography sx={{ fontSize: "1.5rem", fontWeight: 400 }}>
              Favourite
            </Typography>
          </Box>
          <ArrowForwardIosOutlinedIcon />
        </Box>
      </Link>
      <Link to={`/address`} style={{ textDecoration: "none", color: "#000" }}>
        <Box
          sx={{
            display: "flex",
            gap: "8px",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
            <Box
              sx={{
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                bgcolor: "#D9D9D9",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <LocationOnOutlinedIcon sx={{ color: "#9C9C9C" }} />
            </Box>
            <Typography sx={{ fontSize: "1.5rem", fontWeight: 400 }}>
              Address book
            </Typography>
          </Box>
          <ArrowForwardIosOutlinedIcon />
        </Box>
      </Link>
    </Box>
  );
}
