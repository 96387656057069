import { Box, Typography } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70vw",
  height: "auto",
  bgcolor: "#fff",
  borderRadius: "20px",
  boxShadow: 24,
  p: "2rem 2rem",
  margin: "auto",
  display: "flex",
  flexDirection: "column",
};
export default function MinOrderValueModal({ admin, subTotal, handleClose }) {
  return (
    <Box sx={style}>
      <WarningIcon
        sx={{
          color: "#F04141",
          fontSize: "5rem",
          marginInline: "auto",
        }}
      />
      <Typography
        sx={{
          fontSize: "1.6rem",
          fontWeight: "500",
          marginInline: "auto",
          textAlign: "center",
          my: 2,
        }}
      >
        Add&nbsp;
        <span>{admin?.currencySymbol}&nbsp;</span>
        {admin.minOrderValue - subTotal} more to complete the purchase
      </Typography>
      <Typography
        sx={{
          fontSize: "1.2rem",
          fontWeight: "400",
          color: "#AFAFAF",
          marginInline: "auto",
          textAlign: "center",
        }}
      >
        Minimum order value is <span>{admin?.currencySymbol}&nbsp;</span>
        {admin.minOrderValue}
      </Typography>
      <Typography
        onClick={handleClose}
        sx={{
          color: "#F04141",
          marginInline: "auto",
          mt: 2,
          fontSize: "1.6rem",
          fontWeight: "500",
        }}
      >
        OK!
      </Typography>
    </Box>
  );
}
