import axios from "axios";
const host = process.env.REACT_APP_BACKEND_URL;
const adminId = process.env.REACT_APP_ADMIN_ID;

export const loginWithPhone = async (value) => {
  try {
    const data = await axios.post(`${host}/loginWithPhone`, value);
    console.log(data);
    return data;
  } catch (error) {
    return error.response;
  }
};

export const loginWithOutOtp = async (value) => {
  try {
    const data = await axios.post(`${host}/loginWithOutOtp`, value);
    console.log(data);
    return data;
  } catch (error) {
    return error.response;
  }
};

export const sendOtp = async (value) => {
  try {
    const data = await axios.post(`${host}/sendOtp`, value);
    return data;
  } catch (error) {
    return error.response;
  }
};

export const otpVerification = async (value) => {
  try {
    const data = await axios.post(`${host}/otpVerification`, value);
    return data;
  } catch (error) {
    return error.response;
  }
};

export const getAdminDetails = async () => {
  try {
    const data = await axios.get(`${host}/getAdminDetails/${adminId}`);
    return data;
  } catch (error) {
    return error.response;
  }
};
