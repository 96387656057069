import { Box, Typography } from "@mui/material";
import LocationOffIcon from "@mui/icons-material/LocationOff";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70vw",
  height: "auto",
  bgcolor: "#fff",
  borderRadius: "20px",
  boxShadow: 24,
  p: "2rem 2rem",
  margin: "auto",
  display: "flex",
  flexDirection: "column",
};

export default function NotDesiredLocationModal({
  locModalHandleClose,
  deliveryRadius,
}) {
  return (
    <Box sx={style}>
      <LocationOffIcon
        sx={{
          color: "#F04141",
          fontSize: "5rem",
          marginInline: "auto",
        }}
      />
      <Typography
        sx={{
          fontSize: "1.8rem",
          fontWeight: "500",
          marginInline: "auto",
          textAlign: "center",
          my: 2,
        }}
      >
        Sorry you are not on desired location
      </Typography>
      <Typography
        sx={{
          fontSize: "1.2rem",
          fontWeight: "400",
          color: "#AFAFAF",
          marginInline: "auto",
          textAlign: "center",
        }}
      >
        Currently we are only delivering within {deliveryRadius}km radius
      </Typography>
      <Typography
        onClick={locModalHandleClose}
        sx={{
          color: "#F04141",
          marginInline: "auto",
          mt: 2,
          fontSize: "1.6rem",
          fontWeight: "500",
        }}
      >
        OK
      </Typography>
    </Box>
  );
}
