import Cookies from "js-cookie";

export function InstructionReducer(
  state = Cookies.get("instruction")
    ? JSON.parse(Cookies.get("instruction"))
    : "",
  action
) {
  switch (action.type) {
    case "INSTRUCTION":
      return action.payload;
    default:
      return state;
  }
}
