import { useState } from "react";
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100dvh",
    width: "100vw",
    boxSizing: "border-box",
    padding: "0rem 0rem 2.5rem 0rem",
    backgroundColor: "rgba(248, 248, 248, 1)",
    position: "relative",
  },
  body: {
    padding: "0rem 1.5rem 2.5rem 1.5rem",
  },
}));

export default function Payment() {
  const [selectedValue, setSelectedValue] =useState("cod");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Box
        sx={{
          height: "8vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <Link to="/cart">
          <ArrowBackIcon
            sx={{
              fontSize: "3rem",
              position: "absolute",
              left: 10,
              top: 20,
              color: "#000",
            }}
          />
        </Link>
        <Box>
          <Typography sx={{ fontSize: "2rem", fontWeight: "500" }}>
            Payments
          </Typography>
        </Box>
      </Box>
      <Box className={classes.body}>
        <Typography sx={{ fontSize: "1.6rem", fontWeight: "400" }}>
          All payment options
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            bgcolor: "#fff",
            borderRadius: "20px",
            p: 2,
            my: 2,
          }}
        >
          <RadioGroup value={selectedValue} onChange={handleChange}>
            <FormControlLabel
              value="cod"
              control={
                <Radio
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: "3rem",
                    },
                  }}
                />
              }
              label={
                <Typography sx={{ fontSize: "1.7rem", fontWeight: "400" }}>
                  Card / UPI / Net Banking
                </Typography>
              }
              defaultChecked
            />
            <FormControlLabel
              value="online payment"
              control={
                <Radio
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: "3rem",
                    },
                  }}
                />
              }
              label={
                <Typography sx={{ fontSize: "1.7rem", fontWeight: "400" }}>
                  Cash on Delivery
                </Typography>
              }
            />
          </RadioGroup>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            bgcolor: "#fff",
            borderRadius: "20px",
            p: 2,
            my: 2,
          }}
        >
          <Typography sx={{ fontSize: "1.6rem", fontWeight: "400" }}>
            Order Details (1 Item)
          </Typography>
          <Divider />
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography sx={{ fontSize: "1.5rem", fontWeight: "500" }}>
              Cart Total
            </Typography>
            <Typography sx={{ fontSize: "1.5rem", fontWeight: "400" }}>
              ₹ 218
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              sx={{ fontSize: "1.5rem", fontWeight: "400", color: "red" }}
            >
              Discount on MRP
            </Typography>
            <Typography
              sx={{ fontSize: "1.5rem", fontWeight: "400", color: "red" }}
            >
              ₹ 0
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography sx={{ fontSize: "1.5rem", fontWeight: "400" }}>
              Coupon
            </Typography>
            <Typography
              sx={{ fontSize: "1.5rem", fontWeight: "500", color: "red" }}
            >
              Apply Coupon
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography sx={{ fontSize: "1.5rem", fontWeight: "400" }}>
              Delivery Charges
            </Typography>
            <Typography
              sx={{ fontSize: "1.5rem", fontWeight: "400", color: "red" }}
            >
              Free
            </Typography>
          </Box>
          <Divider />
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography sx={{ fontSize: "1.5rem", fontWeight: "400" }}>
              Total Amount
            </Typography>
            <Typography sx={{ fontSize: "1.5rem", fontWeight: "400" }}>
              ₹ 218
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          bgcolor: "#fff",
          height: "10vh",
          width: "100%",
          boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.15)",
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <Box>
          <Typography
            sx={{
              color: "rgba(159, 159, 159, 1)",
              fontSize: "1.2rem",
              fontWeight: "400",
            }}
          >
            Total
          </Typography>
          <Typography sx={{ fontSize: "2rem", fontWeight: "400" }}>
            ₹ 270.00
          </Typography>
        </Box>
        <Button
          sx={{
            backgroundColor: "#FF2E2E",
            color: "#fff",
            width: "150px",
            height: "40px",
            fontSize: "1.1rem",
            borderRadius: "10px",
            "&:hover": {
              background: "#FF2E2E",
            },
          }}
        >
          Continue
        </Button>
      </Box>
    </Box>
  );
}
