import axios from "axios";
const host = process.env.REACT_APP_BACKEND_URL;

export const addAddress = async (values) => {
  try {
    const data = await axios.post(`${host}/addAddress`, values);
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getAllAddress = async (userId) => {
  try {
    const { data } = await axios.get(`${host}/getAllAddress/${userId}`);
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const deleteAddress = async (id) => {
  try {
    const { data } = await axios.delete(`${host}/deleteAddress/${id}`);
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const editAddress = async (id, item) => {
  try {
    const { data } = await axios.put(`${host}/editAddress/${id}`, item);
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
