import { Box, Button, Typography } from "@mui/material";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useFormik } from "formik";
import { otpSchema } from "../../schemas";
import { otpVerification, sendOtp } from "../../sevice/login";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";

const initialValues = {
  otp: "",
};
export default function Verify({ returnSataus, closeprevious, data }) {
  const { admin } = useSelector((state) => state);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: otpSchema,
    onSubmit: async (values, action) => {
      const res = await otpVerification({
        otp: values.otp,
        number: data.number,
        vendorId: admin._id,
        name: data.name,
      });
      if (res.data.status) {
        dispatch({ type: "LOGIN", payload: res?.data?.user });
        Cookies.set("user", JSON.stringify(res?.data?.user));
        returnSataus(res.status);
        closeprevious();
        toast.success("Login Successfull", {
          position: "bottom-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error(res?.data?.message, {
          position: "bottom-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    },
  });

  const handleChange = (newValue) => {
    formik.setFieldValue("otp", newValue);
  };

  const [minutes, setMinutes] = useState(2);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [minutes, seconds]);

  const resendOTP = async () => {
    const res = await sendOtp({ number: data.number });
    console.log(res);
    if (res.status === 200) {
      setMinutes(2);
      setSeconds(59);
    }
  };
  return (
    <Box>
      <Box
        sx={{
          height: "auto",
          margin: "0rem 10px 2rem 10px",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Typography sx={{ fontSize: "2.4rem", fontWeight: "400" }}>
          Verify
        </Typography>
        <Typography
          sx={{
            color: "rgba(144, 144, 144, 1)",
            fontSize: "1.5rem",
            fontWeight: "400",
          }}
        >
          OTP sent to {data.number}
        </Typography>
        <Typography
          sx={{
            fontSize: "1.5rem",
            fontWeight: "400",
          }}
        >
          Enter OTP
        </Typography>
        <MuiOtpInput
          length={6}
          value={formik.values.otp}
          onChange={handleChange}
        />
        {formik.touched.otp && formik.errors.otp && (
          <Typography
            sx={{
              color: "#d32f2f",
              fontSize: "1.3rem",
              fontWeight: "400",
              mt: 0,
            }}
          >
            {formik.errors.otp}
          </Typography>
        )}
        <Typography
          sx={{
            color: "rgba(144, 144, 144, 1)",
            fontSize: "1.3rem",
            fontWeight: "400",
          }}
        >
          Didn’t receive OTP?{" "}
          <span
            style={{ color: "#000" }}
            onClick={() => {
              if (minutes === 0 && seconds === 0) {
                resendOTP();
              }
            }}
          >
            Sent again
          </span>
          {minutes !== 0 || seconds !== 0 ? (
            <span
              style={{
                marginLeft: "5px",
                fontWeight: 500,
                fontSize: "1.4rem",
                color: "green",
              }}
            >
              0{minutes}.{seconds}
            </span>
          ) : (
            ""
          )}
        </Typography>
        <Button
          sx={{
            backgroundColor: "#FF2E2E",
            color: "#fff",
            height: "45px",
            borderRadius: "10px",
            fontSize: "1.3rem",
            fontWeight: "400",
            "&:hover": {
              backgroundColor: "#FF2E2E",
            },
          }}
          type="submit"
          onClick={formik.handleSubmit}
        >
          Continue
        </Button>
        <Typography
          sx={{
            color: "rgba(144, 144, 144, 1)",
            fontSize: "1rem",
            fontWeight: "400",
          }}
        >
          By clicking, I accept the{" "}
          <Link
            to="https://www.app.ouronlineservice.com/terms"
            style={{ textDecoration: "none" }}
          >
            <span style={{ color: "#000" }}>Terms & Conditions</span>
          </Link>{" "}
          &{" "}
          <Link
            to="https://www.app.ouronlineservice.com/privacy"
            style={{ textDecoration: "none" }}
          >
            <span style={{ color: "#000" }}>Privacy Policy</span>
          </Link>
        </Typography>
      </Box>
    </Box>
  );
}
