import React from "react";
import { Circle, GoogleMap, Marker } from "@react-google-maps/api";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";

const App = ({ selectedPlace, setSelectedPlace, adminLatAndLog, admin }) => {
  const mapContainerStyle = ({ display }) => {
    return {
      width: "100%",
      height: window.innerHeight >= 700 ? "73dvh" : "69dvh",
      borderRadius: 4,
      display: display,
    };
  };
  const circleOptions = {
    fillColor: "coral",
    fillOpacity: 0.3,
    strokeWeight: 2,
    strokeColor: "coral",
    zIndex: 1,
  };
  function handleChangeLocation(location) {
    setSelectedPlace({
      lat: location.latLng.lat(),
      lng: location.latLng.lng(),
    });
  }

  return (
    <div>
      <div>
        <GoogleMap
          mapContainerStyle={mapContainerStyle({ display: "block" })}
          center={selectedPlace}
          zoom={11}
          options={{
            streetViewControl: false,
            scaleControl: false,
            mapTypeControl: false,
            zoomControl: false,
          }}
          onClick={handleChangeLocation}
        >
          <Circle
            center={adminLatAndLog}
            radius={Number(admin.deliveryRadius) * 1000}
            options={circleOptions}
            onClick={handleChangeLocation}
          />
          <Marker position={adminLatAndLog} />
          {selectedPlace && <Marker position={selectedPlace} />}
        </GoogleMap>
      </div>
      <Box
        sx={{
          position: "absolute",
          top: 15,
          left: 15,
          bgcolor: "#FFFF",
          height: "35px",
          width: "35px",
          display: "flex",
          borderRadius: "50%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Link to={-1}>
          <ArrowBackIosIcon
            sx={{
              fontSize: "3rem",
              color: "#000",
              ml: 1.2,
            }}
          />
        </Link>
      </Box>
    </div>
  );
};

export default App;
