import React from "react";
import EngineeringIcon from "@mui/icons-material/Engineering";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import { Box, Typography } from "@mui/material";

const PopupStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70vw",
  height: "auto",
  bgcolor: "#000",
  borderRadius: "20px",
  boxShadow: 24,
  p: "2rem 2rem",
  margin: "auto",
  display: "flex",
  flexDirection: "column",
};

export default function CouponNotAvilable({ close }) {
  return (
    <Box>
      <Box sx={PopupStyle}>
        <CardGiftcardIcon
          sx={{
            color: "#F04141",
            fontSize: "5rem",
            marginInline: "auto",
          }}
        />
        <Typography
          sx={{
            fontSize: "1.8rem",
            fontWeight: "500",
            marginInline: "auto",
            textAlign: "center",
            my: 2,
            color: "#fff",
          }}
        >
          There is no coupon avilable
        </Typography>
        <Typography
          sx={{
            fontSize: "1.4rem",
            fontWeight: "400",
            color: "#AFAFAF",
            marginInline: "auto",
            textAlign: "center",
          }}
        >
          {/* will be live soon :) */}
        </Typography>
        <Typography
          onClick={close}
          sx={{
            color: "#F04141",
            marginInline: "auto",
            mt: 2,
            fontSize: "1.6rem",
            fontWeight: "500",
          }}
        >
          OK
        </Typography>
      </Box>
    </Box>
  );
}
