import { Box, Skeleton } from "@mui/material";

export default function BestsellerCardSkeleton({ index }) {
  return (
    <Box
      key={index}
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "190px",
        width: "170px",
        borderRadius: "5px",
        boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.15)",
        position: "relative",
      }}
    >
      <Skeleton
        variant="rectangular"
        width={"170px"}
        height={"190px"}
        sx={{ borderRadius: "5px" }}
      />
    </Box>
  );
}
